@import url('https://fonts.googleapis.com/css?family=Quicksand|Roboto');

$base-font: 'Quicksand', sans-serif;
$color-secondary: #555;
$color-primary: #84BD00;
$color-white: #fff;
$color-google: #de4933;
$color-card-cancel: $color-google;
$color-facebook: #385699;
$color-card-paid: #439dbb;
$color-card-unpaid: #e0a630;
$color-border: #a9a6a6;
$box-border: #9e9494;
$box-shadow: #c3c3c3;
$bg-terms-service: #e7e7e7; 

/* Landscape */
@media only screen   
  and (min-width: 736px) 
  and (max-width: 740px) 
  and (orientation: landscape) {
    
    .container{
        max-width: none !important;  
    }

	.bm-item {
        display: inline-block;
        text-decoration: none;
        margin-bottom: 10px;
        color: $color-secondary;
        transition: color 0.2s;
    }

    .bm-item:hover {
        color: $color-white;
    } 

    .title {
        font-family: $base-font;
        font-weight: 700; 
        font-style: normal;
        color: $color-secondary;
    }

    .lines-title {
        width: 100px;
        height: 20px;
        margin-bottom: 20px;
    }

    .btn-green {
        background: $color-primary;
    }

    .text-green {
        color: $color-primary;
        font-weight: 800;
    }

    header {
        width: 100%;
        position: fixed;
        left: 0px;
        background: #efefef;
        padding: 10px 10px 10px 50px;
        font-size: 30px;
        line-height: 30px;
        z-index: 0;
    }

    .header-top {
        background: $color-white;
        height: 70px;
        overflow: hidden;
        position: fixed !important;
        top: 0;
    }

    .btn-login {
        background: $color-primary !important;
        margin-top: 20px;
        border: none !important;
        color: $color-white !important;
        font-weight: 900 !important;
        height: 40px;
    }

    .btn-auth {
        margin: 10px 0px 20px 28px;
    }

    .btn-google {
        background: $color-google !important;
        border: none !important;
        width: 100% !important;
        padding: 9px !important;
        margin-top: 6px !important;
        color: $color-white !important;
        font-weight: 600 !important;
    }

    .btn-facebook {
        background: $color-facebook !important;
        border: none !important;
        width: 100% !important;
        padding: 9px;
        margin-top: 6px;
        color: $color-white !important;
        font-weight: 600;
    }

    .calculator {
        // background: $color-white;
        font-size: 12px !important;
        margin-bottom: 30px;
    }

    .img-icon-calc {
        padding-top: 30px;
        width: 35px; 
    }

    .payment-method {
        background: $color-white;
        padding-bottom: 40px;
    }

    .card-paid {
        background: $color-card-paid;
        color: #878787 !important;
        position: absolute !important;
        font-weight: 900 !important;
        padding: 25px 25px 20px 60px !important;
        color: $color-white !important;
        -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important;
    }

    .card-unpaid {
        background: $color-card-unpaid;
        color: #878787 !important;
        position: absolute !important;
        font-weight: 900 !important;
        padding: 25px 25px 20px 60px !important;
        color: $color-white !important;
        -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important;
    }

    .card-cancel {
        background: $color-google;
        color: #878787 !important;
        position: absolute !important;
        font-weight: 900 !important;
        padding: 25px 25px 20px 60px !important;
        color: $color-white !important;
        -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important;
    }


    .select-component {
        font-size: 13px !important;;
        display: block;
        width: 100%;
        height: calc(2.25rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: $color-white;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .logo-header { 
        width: 195px !important;
        margin-left: 98%;
        margin-top: 12px;
    }

    .header-logo {
        background:  $color-primary;
        position: fixed;
        height: 100px;
    }

    .logo-sidebar {
        width: 180px;
        padding: 0px;
        margin: 0px 0px 0px 35%;
    }

    .title-calc {
        font-family: $base-font;
        font-weight: 700;
        font-style: normal;
        text-align: center;
        font-size: 15px;
        padding-top: 15px;
        color: $color-secondary;
    }

    .lines-title-calculator {
        width: 150px;
        margin-bottom: 25px; 
    }

    .card-payment {
        margin-top: 20px;
    }

    .img-payment {
        height: 78px !important;
        width: 85% !important;
    }

    .term-service-general {
        background: $bg-terms-service;
    }

    .title-term-service {
        font-weight: bold;
        color: $color-secondary;
    }

    .content-term-service {
        color: $color-secondary;
        font-size: 12px;
        text-align: justify;
    }

    .ol-number {
        list-style-type: number;
    }

    .ol-lower-alpha {
        list-style-type: lower-alpha;
    }
    .date-program {
        text-align: right;
    }

    .title-green-program {
        font-family: $base-font;
        font-weight: 700;
        padding: 20px 0px 20px 0px;
        font-style: normal;
        color:  $color-primary;
        font-size: 14px;
    }

    .image-program {
        width: 100%;
        height: 270px !important;
        margin-top: -20px !important;
        background: $color-primary;
    }
    .image-program-detail {
        width: 100%;
        height: 100%;
        margin-top: -0px !important;
        background: $color-primary;
    }

    /* gets the actual input out of the way;
    we're going to style the label instead */

    #drawer-toggle {
        position: absolute;
        opacity: 0;
    }

    #drawer-toggle-label {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        left: 0px;
        height: 50px;
        width: 50px;
        display: block;
        position: fixed;
        background: rgba(255, 255, 255, .0);
        z-index: 1;
    }

    /* adds our "hamburger" menu icon */

    #drawer-toggle-label:before {
        content: '';
        display: block;
        position: absolute;
        height: 2px;
        width: 24px;
        background: #8d8d8d;
        left: 13px;
        top: 18px;
        box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d;
    }


    /* drawer menu pane - note the 0px width */

    #drawer {
        position: fixed;
        top: 0;
        left: -300px;
        height: 100%;
        width: 300px;
        background: #2f2f2f;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 20px;
        -webkit-overflow-scrolling: touch;
    }

    /* actual page content pane */

    #page-content {
        margin-left: 0px;
        width: 100%;
        height: calc(100% - 50px);
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        padding: 20px;
    }

    /* checked styles (menu open state) */

    #drawer-toggle:checked ~ #drawer-toggle-label {
        height: 200%;
        width: calc(100% - 300px);
        background: rgba(89, 89, 89, 0.8);
    }

    #drawer-toggle:checked ~ #drawer-toggle-label,
    #drawer-toggle:checked ~ header {
        left: 300px;
    }

    #drawer-toggle:checked ~ #drawer {
        left: 0px;
    }

    #drawer-toggle:checked ~ #page-content {
        margin-left: 300px;
    }

    /* Menu item styles */

    #drawer ul {
        list-style-type: none;
    }

    #drawer ul a {
        display: block;
        padding: 10px;
        color: #c7c7c7;
        text-decoration: none;
    }

    #drawer ul a:hover {
        color: $color-white;
    }
    
    .card-donation {
        width: 100%;
        height: 115px;
        background: $color-white;
        margin-left: 12px;
        border: 1px solid #f1f1f1;
        box-shadow: 0 10px 6px -6px $box-shadow;
    }

    .row-menu {
        padding: 10px;
    }
    .row-service {
        margin-bottom: 70px;
        margin-left: 10px !important;
        padding: 0px 120px;
    }
    .row-service-top {
        margin-top: 10px !important;
    }
    .rotate-text {
        /* Safari */
        -webkit-transform: rotate(-90deg);
        /* Firefox */
        -moz-transform: rotate(-90deg);
        /* IE */
        -ms-transform: rotate(-90deg);
        /* Opera */
        -o-transform: rotate(-90deg);
        float: left;
        margin-top: 25px;
        color: $color-white;
        font-weight: bold;
    }

    .square-right {
        position: absolute;
        width: 140px;
        z-index: -1;
        right: 0 !important; 
    }

    .square-left{
        position: absolute;
        width: 127px;
        z-index: -1;
    }

    .line-vertical {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid $color-border;
        line-height: 0.1em;
        margin: 30px 0 20px;
        font-size: 17px;
    }

    .text-login-or {
        background: $color-white;
        padding: 0px 7px;
    }

    .slider {
        height: 120px;
        padding: 0px !important;
        background: $color-primary;
        margin-right: 0px !important;
        margin-left: 0px !important;
    }

    .services {
        background: $color-white;
        padding-bottom: 40px;
    }

    .img-slider {
        width: 100%;
        height: 220px !important;
    }

    .img-service {
        width: 100%;
        height: 44px;
    }

    .service-title-3 {
        font-size: 12px;
        line-height: normal !important;
        padding-top: 6px;
        font-weight: 600 !important;
        color: $color-secondary !important;
        text-align: center !important;
    }
    .service-title-4 {
        font-size: 11px;
        line-height: normal !important;
        padding-top: 6px;
        text-align: center !important;
    }
    .card-image {
        text-align: center;
        width: 50%;
        margin: 0 auto;
        margin-top: 20%;
    }

    .icon-dashboard {
        width: 20px;
        height: 20px;
        margin-left: 10px;
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .programs {
        // padding-bottom: 30px;
        // background: $color-primary;
    }

    .slick-slide {
        height: 100%;
        box-sizing: border-box;
    }

    .slick-slide img {
        height: 180px;
        width: 100%;
        box-sizing: border-box;
    }

    .card-program {
        margin-top: 20px;
        background: $color-white;
        width: 400px;
        height: 150px;
        color: $color-secondary !important;
    }
    
    .text-collected {
        font-size: 12px;
        padding-left: 7px;
    }

    .text-date {
        font-size: 12px;
        text-align: right !important;
        float: right;
    }

    .text-title-program {
        font-weight: bold;
        font-size: 11px;
    }

    .title-green {
        font-family: $base-font;
        font-weight: 700;
        font-style: normal;
        color:  $color-primary; 
        text-align: center;
    }

    .img-layanan { 
        height: 42px;
        margin-left: 45%;
    }
 
    .footer-small {
        background-size: cover;
        background-repeat: no-repeat;
        height: 40px;
    }

    .card-program-list {
        margin-top: 20px;
        background: $color-white;
        border: 1px solid #dcdada;
        box-shadow: 0 10px 6px -6px $box-shadow;
        margin-bottom: 50px;
    } 
    .btn-register-bottom {
        margin-top: 120px;
        font-size: 12px !important;
        background: $color-secondary !important;
        margin-left: 30px;
    }
    .btn-playstore {
        width: 120px !important; 
        margin: 15px 0px 15px 40px !important; 
    }

    .footer-dashboard{
        height: 300px;
        background: #424141;
        border-top-left-radius: 0%;
        border-top-right-radius: 90%;
     } 
    
     .span-find-social{
        color: $color-white;
        margin-left: 100px;
        margin-top: 25px;
        font-weight: bold;
        font-size: 16px;
     }

    .progress-program {
        background-color: $color-white;
        border: 1px solid $color-primary;
        border-radius: 0px !important;
    }

    .bg-success {
        background-color: $color-primary !important;
        font-weight: bold;
    }

    .btn-infaq {
        border-radius: 0px !important;
        background:  $color-primary !important;
        border: none !important;
        font-weight: bold !important;
        color: $color-white !important;
        text-decoration: none !important;
    }

    .btn-lainnya {
        background-color: #2096f3 !important;
        border: none !important;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .avatar-user {
        height: 80px !important; 
        border-radius: 100% !important; 
    } 

    .service-title-others {
        line-height: normal !important;
        text-align: center !important;
        font-size: 13px;
        font-weight: 600 !important;
        color: #555 !important;
        margin: 0 auto;
        margin-top: 20%;
    }

    .img-update-detail { 
        height: 135px !important;
        width: 190px !important;
    }

    .button-fixed {
        overflow: hidden;
        position: fixed !important; 
        bottom: 0;
        height: 50px;
        font-weight: 900 !important;
        background:  $color-primary !important;
        border: none !important;
    }
    .button-fixed-logout {
        overflow: hidden;
        position: fixed !important;
        bottom: 0;
        height: 50px;
        font-weight: 900 !important;
        background: $color-google !important;
        border: none !important;
        width: 93.6%; 
        color: $color-white !important;
    }
    .btn-color-primary {
        background: $color-primary !important;
    }

    .btn-color-secondary {
        background: #747474 !important;
    }
    /* after data */
    .service-square-4 {
        height: 100px;
        max-width: 23.7% !important;
        margin: 2px;
        margin-bottom: 10px;
        background: $color-white;
        border: 1px solid #f1f1f1;
        box-shadow: 0 10px 6px -6px #c3c3c3 !important;
    }  
    .service-square-3 {
        height: 100px;
        max-width: 30% !important;
        margin: 2px;
        background: $color-white;
        border: 1px solid #f1f1f1;
        box-shadow: 0 10px 6px -6px #c3c3c3 !important;
    }
    
    .tabs-program {
        margin-top: 40px !important;
        margin-bottom: 70px !important;
    }

    .modal-program-category {
        transform: translate3d(0px, 0px, 0px);
        z-index: 10002;
        width: 280px;
        background-color: $color-white;
        border-radius: 0px !important;
        box-sizing: border-box;
        padding: 17px !important;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
        transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    }

    details {
        width: 65%;
        min-height: 5px;
        max-width: 700px;
        padding: 14px 5px 14px 14px;
        margin: 0 auto;
        position: relative;
        font-size: 14px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        -webkit-transition: all .3s;
        transition: all .3s;
    }

    details + details {
        margin-top: 10px;
    }

    details[open] {
        min-height: 50px;
        background-color: #f6f7f8;
        box-shadow: 2px 2px 20px rgba(0, 0, 0, .2);
    }

    details p {
        color: #96999d;
        font-weight: 300;
    }

    summary {
        font-weight: 500;
        cursor: pointer;
    }
 
    summary:focus {
        outline: none;
    }

    summary::-webkit-details-marker {
        display: none
    }

    summary::after {
        padding: 20px;
        position: absolute;
        top: 50%;
        right: 0;
        font-style: normal;
        font-variant-caps: normal;
        font-variant-ligatures: normal;
        font-weight: 900 !important;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        transform: translateY(-50%);
        transition: .3s ease;
    }

    details[open] summary::after {
        top: 0;
        transform: translateY(0);
        transition: .3s ease;
    }

    details[open] summary:hover::after {
        animation: pulse 1s ease;
    }

    @keyframes pulse {
        25% {
            transform: scale(1.1);
        }
        50% {
            transform: scale(1);
        }
        75% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
    .img-layanan-4 {
        height: 38px;
        margin-left: 35%;
    }
    
    .link-active:active{
        border-bottom: 2px solid  $color-primary;
    }

    .button-share {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .close-sidebar{
        width: 25% !important;
    }
      
    .img-profile-sidebar { 
        height: 63px !important;
        border-radius: 100% !important;
        margin-top: -10px !important; 
        border: 2px solid #fff !important;
        box-shadow: 0 10px 6px -6px #c3c3c3 !important;
        margin-left: 50% !important;
    }

    .service-popular-row{
        margin-top: 70px; 
        margin-bottom: 20px;
        padding: 50px 110px 0px 110px;
    }
 
    .box-program-col{
        padding: 50px 100px 0px 100px;
    }

    .row-calculator {
        padding: 40px;
    }

    .text-footer-small{
        text-align: right;
        color: #fff;
        font-weight: bold;
        font-size: 10px;
    }
    
    .btn-login-sidebar {
        background: #555 !important;
        border: none !important;
        font-weight: 600 !important;
        color: white !important;
        height: 45px !important;
    }

    .btn-regis-sidebar {
        background: #84BD00 !important;
        border: none !important;
        font-weight: 600 !important;
        color: white !important;
        height: 45px !important;
    }
    .pd-ls{
        padding: 0px 100px;
    }
    .pd-ls-lg{
        padding: 0px 100px;
    }
    .btn-pull{
        margin-left: 30%;
    }

    .row-child-sidebar, .row-btn-sidebar{
        padding: 10px 120px !important;
    }
    .ic-social-footer {
        margin-top: 30px;
        width: 60% !important;
    }
}
 