@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Quicksand|Roboto");
* {
  font-family: "Quicksand", sans-serif; }

a {
  text-decoration: none !important; }

.text-menu-sidebar {
  margin-left: 25px;
  color: #555; }

.box {
  overflow: hidden; }

.box ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  transition: all 0.5s linear;
  top: 0; }

.box ul:before {
  content: "";
  display: block;
  width: 0;
  height: 100%;
  border: 1px dashed #555;
  position: absolute;
  top: 0;
  left: 30px; }

.box ul li {
  margin: 20px 60px 60px;
  position: relative;
  background: white;
  color: #555;
  padding: 15px;
  /* border-radius: 10px; */
  font-size: 12px;
  line-height: 20px;
  border: 1px solid #f1f1f1;
  width: 80%;
  box-shadow: 0 10px 6px -6px #c3c3c3; }

.box ul li > span {
  content: "";
  display: block;
  height: 100%;
  border: 1px solid #555;
  position: absolute;
  top: 0;
  left: -31px; }

.box ul li > span:before,
.box ul li > span:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #555;
  border: 2px solid #555;
  position: absolute;
  left: -7.5px; }

.box ul li > span:before {
  top: -10px; }

.box ul li > span:after {
  top: 95%; }

.box .title {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 5px; }

.box .info:first-letter {
  text-transform: capitalize;
  line-height: 1.7; }

.box .name {
  margin-top: 10px;
  text-transform: capitalize;
  font-style: italic;
  text-align: right;
  margin-right: 20px; }

.box .time span {
  position: absolute;
  left: -100px;
  color: #555;
  font-size: 80%;
  font-weight: bold; }

.box .time span:first-child {
  top: -16px; }

.box .time span:last-child {
  top: 94%; }

.box ul:last-of-type .arrow {
  position: absolute;
  top: 105%;
  left: 22%;
  transform: rotateX(180deg);
  cursor: pointer; }

svg {
  width: 20px;
  height: 20px; }

@keyframes arrow {
  0%,
  100% {
    top: 105%; }
  50% {
    top: 106%; } }

@-webkit-keyframes arrow {
  0%,
  100% {
    top: 105%; }
  50% {
    top: 106%; } }

@-moz-keyframes arrow {
  0%,
  100% {
    top: 105%; }
  50% {
    top: 106%; } }

@-o-keyframes arrow {
  0%,
  100% {
    top: 105%; }
  50% {
    top: 106%; } }

.service-square-4,
.service-square-3,
.card-program,
.form-control,
.alert-calculator,
.select-component,
details,
.btn-facebook,
.btn-rounded,
.modal-program-category,
.btn-google,
.card-donation,
.card-paid,
.card-unpaid,
.card-cancel,
.timeline-content,
.img-update-detail {
  border-radius: 4px !important; }

.btn-flat {
  border-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important; }

.image-program {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important; }

.progress-program {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.card-program-list {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.slick-slide img {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important; }

.btn-program {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.btn-category {
  background: #3b3b3b !important;
  color: #fff !important; }

.col-list-donation {
  margin-bottom: 30px; }

.row-donation {
  padding-bottom: 30px; }

.btn-login-sidebar {
  background: #555 !important;
  border: none !important;
  font-weight: 600 !important;
  color: white !important; }

.btn-regis-sidebar {
  background: #84BD00 !important;
  border: none !important;
  font-weight: 600 !important;
  color: white !important; }

.btn-save-profile {
  float: right;
  background: #84BD00 !important;
  border: 0px !important;
  border: none;
  color: white !important; }

.row-info-profile {
  margin-bottom: 30px; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none; }

.read-more-state {
  display: none; }

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: .25s ease; }

.read-more-state:checked ~ .read-more-wrap .read-more-target {
  opacity: 1;
  font-size: inherit;
  max-height: 999em; }

.read-more-state ~ .read-more-trigger:before {
  content: 'Show more'; }

.read-more-state:checked ~ .read-more-trigger:before {
  content: 'Show less'; }

.read-more-trigger {
  cursor: pointer;
  display: inline-block;
  padding: 0 .5em;
  color: #666;
  font-size: .9em;
  line-height: 2;
  border: 1px solid #ddd;
  border-radius: .25em; }

#timeline .timeline-item:after, #timeline .timeline-item:before {
  content: '';
  display: block;
  width: 100%;
  clear: both; }

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box; }

#timeline {
  width: 100%;
  margin: 30px;
  padding: 0px;
  width: 90%;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease; }
  #timeline:before {
    content: "";
    width: 2px;
    height: 100%;
    background: #dee2e6;
    left: -7px;
    top: 0;
    position: absolute; }
  #timeline:after {
    content: "";
    clear: both;
    display: table;
    width: 100%; }
  #timeline .timeline-item {
    margin-bottom: 50px;
    position: relative;
    left: -15px; }
    #timeline .timeline-item .timeline-icon {
      background: #8d8d8d;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 0;
      left: 16px;
      overflow: hidden;
      margin-left: -15px;
      border-radius: 50%; }
      #timeline .timeline-item .timeline-icon svg {
        position: relative;
        top: 0px;
        left: 6px; }
    #timeline .timeline-item .timeline-content {
      width: 90%;
      float: right;
      background: white;
      padding: 20px;
      border: 1px solid #f1f1f1;
      box-shadow: 0 10px 6px -6px #c3c3c3;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      #timeline .timeline-item .timeline-content h2 {
        padding: 15px;
        color: #555;
        margin: -20px -20px 0 -20px;
        font-weight: 300;
        -webkit-border-radius: 3px 3px 0 0;
        -moz-border-radius: 3px 3px 0 0;
        -ms-border-radius: 3px 3px 0 0;
        border-radius: 3px 3px 0 0; }
      #timeline .timeline-item .timeline-content:before {
        content: '';
        position: absolute;
        top: 20px;
        width: 0;
        height: 0;
        left: 10%; }

.program-description {
  font-size: 13px;
  background: white;
  padding-top: 10px;
  height: 90px !important; }

.card-program:active {
  -webkit-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3); }

.service-square-4:active {
  background: #c8e1fa; }

.service-square-3:active {
  background: #c8e1fa; }

.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center; }

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #84BD00;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both; }

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.sk-fading-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative; }

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #84BD00;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both; }

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg); }

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg); }

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg); }

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg); }

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg); }

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg); }

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg); }

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg); }

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s; }

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s; }

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

.link {
  text-decoration: none; }

blockquote {
  font-size: 12px;
  color: #555;
  background: #f9f9f9;
  border-left: 5px solid #ccc;
  margin: .5em 10px;
  padding: 0 10px;
  quotes: "“" "”" "‘" "’";
  padding: 10px 20px;
  line-height: 1.4; }

blockquote:before {
  content: open-quote;
  display: inline;
  height: 0;
  line-height: 0;
  left: -10px;
  position: relative;
  top: 30px;
  color: #ccc;
  font-size: 4em; }

p {
  margin: 0; }

footer-quote {
  margin: 0;
  text-align: right;
  font-size: 1em;
  font-style: italic; }

.share-program {
  text-align: center;
  font-weight: bold;
  color: #84BD00; }

.footer-bottom {
  height: 40px;
  background: #7b7b7b;
  text-align: right;
  line-height: 14px; }

.navbar {
  background: #fff; }

.transaction_date {
  font-weight: bold !important;
  font-size: 15px !important; }

.copy {
  background: #555 !important;
  border: none !important;
  color: #fff !important; }

.card-body {
  box-shadow: 0 10px 6px -6px #c3c3c3 !important; }

.sidepanel {
  width: 0;
  position: fixed;
  z-index: 1;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s; }

.sidepanel a {
  text-decoration: none;
  color: #84bd02;
  display: block;
  transition: 0.3s; }

.sidepanel .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px; }

.openbtn {
  font-size: 25px;
  cursor: pointer;
  background: #fff;
  color: #555;
  border: none;
  margin-top: 5px;
  margin-bottom: 5px; }

#fixed-logout {
  left: 0;
  bottom: 0;
  width: 105%;
  background-color: #de4933;
  text-align: center;
  padding: 0px !important;
  border-radius: 4px;
  visibility: visible; }

.input-group-text {
  font-weight: bold !important; }

.image-cart {
  width: 250px !important;
  height: 150px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  box-shadow: rgba(27, 27, 27, 0.2) 0px 2px 4px 0px !important;
  border-radius: 4px; }

.card-tagihan {
  box-shadow: none !important; }

.row-category {
  height: 55px;
  padding: 10px;
  margin-bottom: 5px; }

.row-category:active {
  height: 55px;
  padding: 10px;
  border-left: 3px solid #84BD00;
  margin-bottom: 5px;
  background: #f2f2f2; }

.title-program {
  height: 50px !important; }

.rippleOut {
  background: #84BD00;
  height: 50px;
  border-right: 1px solid #fff;
  padding-top: 10px;
  font-weight: bold; }

.rippleOut:active {
  background: #84BD00; }

.profile-menu {
  height: 50px;
  color: #84BD00 !important;
  cursor: pointer; }

.profile-menu:active {
  color: #555 !important; }

.pulsate-bck {
  -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
  animation: pulsate-bck 0.5s ease-in-out infinite both; }

.text-sidebar-name {
  color: #555 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding-left: 7% !important; }

.text-sidebar-email {
  color: #84BD00 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding-left: 7% !important; }

@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.ic-social-footer {
  width: 100%; }

.row-btn-sidebar {
  margin: 30px 80px; }

.cart-empty {
  color: #e9e9e9;
  margin-top: 90px;
  margin-left: 37%; }

.avatar-user {
  height: 60px;
  border-radius: 100%;
  margin-top: -45px;
  margin-left: 37%;
  border: 4px solid #fff;
  margin-bottom: 9px; }

.square-right {
  right: 0 !important; }

.text-footer-small {
  font-size: 10px;
  color: #fff;
  font-weight: bold; }

.title-green {
  margin: auto; }

.lines-title-calculator {
  margin-right: auto;
  margin-left: auto;
  height: 35px; }

.avatar-user {
  margin-left: auto;
  margin-right: auto; }

.img-icon-calc {
  margin: auto; }

.btn-hover {
  width: 150px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  height: 45px;
  text-align: center;
  border: none;
  background-size: 200% 100%;
  border-radius: 50px;
  /* bottom: 20px; */
  position: fixed;
  bottom: 60px;
  z-index: 2;
  moz-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  margin: auto;
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both; }

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out; }

.btn-hover:focus {
  outline: none; }

.btn-hover.color-8 {
  background-image: linear-gradient(to right, #737373, #494a48, #555, #70706e);
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75); }

.btn-hover--hidden {
  -webkit-animation: fade-out-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-out-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@-webkit-keyframes fade-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0; } }

@keyframes fade-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0; } }

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

.title-list-view {
  font-size: 0.8rem !important;
  font-weight: bold !important;
  line-height: 1.5 !important; }

.progress-program-tag {
  background-color: #eeeeee !important;
  border-radius: 0px !important;
  height: 0.2rem !important;
  border: none !important; }

ul.ks-cboxtags {
  list-style: none; }

ul.ks-cboxtags li {
  display: inline; }

ul.ks-cboxtags li label {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #84bd02;
  color: #84bd02;
  border-radius: 25px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all .2s; }

ul.ks-cboxtags li label {
  padding: 8px 12px; }

ul.ks-cboxtags li label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  transition: transform .3s ease-in-out; }

ul.ks-cboxtags li input[type="checkbox"]:checked + label::before {
  transition: transform .3s ease-in-out; }

ul.ks-cboxtags li input[type="checkbox"]:checked + label {
  border: 2px solid #84bd02;
  background-color: #84bd02;
  color: #fff;
  transition: all .2s; }

ul.ks-cboxtags li input[type="checkbox"] {
  display: absolute; }

ul.ks-cboxtags li input[type="checkbox"] {
  position: absolute;
  opacity: 0; }

ul.ks-cboxtags li input[type="checkbox"]:focus + label {
  border: 2px solid #e9a1ff; }

.hide {
  display: none; }

.show {
  visibility: visible; }

.selected-round {
  display: none; }
  .selected-round:not(:disabled) ~ label {
    cursor: pointer; }
  .selected-round:disabled ~ label {
    color: #bcc2bf;
    border-color: #bcc2bf;
    box-shadow: none;
    cursor: not-allowed; }

.round-label {
  height: 100%;
  background: white;
  border: 1px solid #84BD00;
  border-radius: 20px;
  margin-bottom: 1rem;
  text-align: center;
  position: relative;
  padding: 5px 10px; }

.selected-round:checked + label {
  background: #84BD00;
  color: white; }
  .selected-round:checked + label::after {
    color: #3d3f43;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    background: white; }

.btn-modal-infaq {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #84bd02;
  cursor: pointer;
  height: 50px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  border: none;
  background-size: 200% 100%;
  border-radius: 50px;
  bottom: 60px;
  z-index: 2;
  moz-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  margin: auto;
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both; }

.image-profile-hashtag {
  border: 5px solid #f5f8fa;
  height: 80px;
  width: 80px;
  border-radius: 100px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  top: -40px;
  margin-bottom: -190px;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.line-home {
  background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(191, 191, 191, 0.75)), to(transparent));
  background-image: linear-gradient(90deg, transparent, rgba(191, 191, 191, 0.75), transparent);
  height: 1px;
  display: block;
  width: 50%;
  margin-bottom: 20px; }

.text-amount-zakat {
  font-weight: bold;
  font-size: 14px; }

label#label-radio-zakat {
  background: none !important;
  color: #555 !important;
  background-image: none !important; }
