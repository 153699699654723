@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
@charset "UTF-8";
* {
  font-family: "Quicksand", sans-serif; }

a {
  text-decoration: none !important; }

.text-menu-sidebar {
  margin-left: 25px;
  color: #555; }

.box {
  overflow: hidden; }

.box ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  top: 0; }

.box ul:before {
  content: "";
  display: block;
  width: 0;
  height: 100%;
  border: 1px dashed #555;
  position: absolute;
  top: 0;
  left: 30px; }

.box ul li {
  margin: 20px 60px 60px;
  position: relative;
  background: white;
  color: #555;
  padding: 15px;
  /* border-radius: 10px; */
  font-size: 12px;
  line-height: 20px;
  border: 1px solid #f1f1f1;
  width: 80%;
  box-shadow: 0 10px 6px -6px #c3c3c3; }

.box ul li > span {
  content: "";
  display: block;
  height: 100%;
  border: 1px solid #555;
  position: absolute;
  top: 0;
  left: -31px; }

.box ul li > span:before,
.box ul li > span:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #555;
  border: 2px solid #555;
  position: absolute;
  left: -7.5px; }

.box ul li > span:before {
  top: -10px; }

.box ul li > span:after {
  top: 95%; }

.box .title {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 5px; }

.box .info:first-letter {
  text-transform: capitalize;
  line-height: 1.7; }

.box .name {
  margin-top: 10px;
  text-transform: capitalize;
  font-style: italic;
  text-align: right;
  margin-right: 20px; }

.box .time span {
  position: absolute;
  left: -100px;
  color: #555;
  font-size: 80%;
  font-weight: bold; }

.box .time span:first-child {
  top: -16px; }

.box .time span:last-child {
  top: 94%; }

.box ul:last-of-type .arrow {
  position: absolute;
  top: 105%;
  left: 22%;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  cursor: pointer; }

svg {
  width: 20px;
  height: 20px; }

@keyframes arrow {
  0%,
  100% {
    top: 105%; }
  50% {
    top: 106%; } }

@-webkit-keyframes arrow {
  0%,
  100% {
    top: 105%; }
  50% {
    top: 106%; } }

.service-square-4,
.service-square-3,
.card-program,
.form-control,
.alert-calculator,
.select-component,
details,
.btn-facebook,
.btn-rounded,
.modal-program-category,
.btn-google,
.card-donation,
.card-paid,
.card-unpaid,
.card-cancel,
.timeline-content,
.img-update-detail {
  border-radius: 4px !important; }

.btn-flat {
  border-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important; }

.image-program {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important; }

.progress-program {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.card-program-list {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.slick-slide img {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important; }

.btn-program {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.btn-category {
  background: #3b3b3b !important;
  color: #fff !important; }

.col-list-donation {
  margin-bottom: 30px; }

.row-donation {
  padding-bottom: 30px; }

.btn-login-sidebar {
  background: #555 !important;
  border: none !important;
  font-weight: 600 !important;
  color: white !important; }

.btn-regis-sidebar {
  background: #84BD00 !important;
  border: none !important;
  font-weight: 600 !important;
  color: white !important; }

.btn-save-profile {
  float: right;
  background: #84BD00 !important;
  border: 0px !important;
  border: none;
  color: white !important; }

.row-info-profile {
  margin-bottom: 30px; }

* {
  box-sizing: border-box;
  outline: none; }

.read-more-state {
  display: none; }

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  -webkit-transition: .25s ease;
  transition: .25s ease; }

.read-more-state:checked ~ .read-more-wrap .read-more-target {
  opacity: 1;
  font-size: inherit;
  max-height: 999em; }

.read-more-state ~ .read-more-trigger:before {
  content: 'Show more'; }

.read-more-state:checked ~ .read-more-trigger:before {
  content: 'Show less'; }

.read-more-trigger {
  cursor: pointer;
  display: inline-block;
  padding: 0 .5em;
  color: #666;
  font-size: .9em;
  line-height: 2;
  border: 1px solid #ddd;
  border-radius: .25em; }

#timeline .timeline-item:after, #timeline .timeline-item:before {
  content: '';
  display: block;
  width: 100%;
  clear: both; }

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box; }

#timeline {
  width: 100%;
  margin: 30px;
  padding: 0px;
  width: 90%;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease; }
  #timeline:before {
    content: "";
    width: 2px;
    height: 100%;
    background: #dee2e6;
    left: -7px;
    top: 0;
    position: absolute; }
  #timeline:after {
    content: "";
    clear: both;
    display: table;
    width: 100%; }
  #timeline .timeline-item {
    margin-bottom: 50px;
    position: relative;
    left: -15px; }
    #timeline .timeline-item .timeline-icon {
      background: #8d8d8d;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 0;
      left: 16px;
      overflow: hidden;
      margin-left: -15px;
      border-radius: 50%; }
      #timeline .timeline-item .timeline-icon svg {
        position: relative;
        top: 0px;
        left: 6px; }
    #timeline .timeline-item .timeline-content {
      width: 90%;
      float: right;
      background: white;
      padding: 20px;
      border: 1px solid #f1f1f1;
      box-shadow: 0 10px 6px -6px #c3c3c3;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      #timeline .timeline-item .timeline-content h2 {
        padding: 15px;
        color: #555;
        margin: -20px -20px 0 -20px;
        font-weight: 300;
        border-radius: 3px 3px 0 0; }
      #timeline .timeline-item .timeline-content:before {
        content: '';
        position: absolute;
        top: 20px;
        width: 0;
        height: 0;
        left: 10%; }

.program-description {
  font-size: 13px;
  background: white;
  padding-top: 10px;
  height: 90px !important; }

.card-program:active {
  box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3); }

.service-square-4:active {
  background: #c8e1fa; }

.service-square-3:active {
  background: #c8e1fa; }

.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center; }

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #84BD00;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both; }

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.sk-fading-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative; }

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #84BD00;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both; }

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg); }

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg); }

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg); }

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg); }

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg); }

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg); }

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg); }

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg); }

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg); }

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s; }

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s; }

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

.link {
  text-decoration: none; }

blockquote {
  font-size: 12px;
  color: #555;
  background: #f9f9f9;
  border-left: 5px solid #ccc;
  margin: .5em 10px;
  padding: 0 10px;
  quotes: "\201C" "\201D" "\2018" "\2019";
  padding: 10px 20px;
  line-height: 1.4; }

blockquote:before {
  content: open-quote;
  display: inline;
  height: 0;
  line-height: 0;
  left: -10px;
  position: relative;
  top: 30px;
  color: #ccc;
  font-size: 4em; }

p {
  margin: 0; }

footer-quote {
  margin: 0;
  text-align: right;
  font-size: 1em;
  font-style: italic; }

.share-program {
  text-align: center;
  font-weight: bold;
  color: #84BD00; }

.footer-bottom {
  height: 40px;
  background: #7b7b7b;
  text-align: right;
  line-height: 14px; }

.navbar {
  background: #fff; }

.transaction_date {
  font-weight: bold !important;
  font-size: 15px !important; }

.copy {
  background: #555 !important;
  border: none !important;
  color: #fff !important; }

.card-body {
  box-shadow: 0 10px 6px -6px #c3c3c3 !important; }

.sidepanel {
  width: 0;
  position: fixed;
  z-index: 1;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s; }

.sidepanel a {
  text-decoration: none;
  color: #84bd02;
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s; }

.sidepanel .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px; }

.openbtn {
  font-size: 25px;
  cursor: pointer;
  background: #fff;
  color: #555;
  border: none;
  margin-top: 5px;
  margin-bottom: 5px; }

#fixed-logout {
  left: 0;
  bottom: 0;
  width: 105%;
  background-color: #de4933;
  text-align: center;
  padding: 0px !important;
  border-radius: 4px;
  visibility: visible; }

.input-group-text {
  font-weight: bold !important; }

.image-cart {
  width: 250px !important;
  height: 150px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  box-shadow: rgba(27, 27, 27, 0.2) 0px 2px 4px 0px !important;
  border-radius: 4px; }

.card-tagihan {
  box-shadow: none !important; }

.row-category {
  height: 55px;
  padding: 10px;
  margin-bottom: 5px; }

.row-category:active {
  height: 55px;
  padding: 10px;
  border-left: 3px solid #84BD00;
  margin-bottom: 5px;
  background: #f2f2f2; }

.title-program {
  height: 50px !important; }

.rippleOut {
  background: #84BD00;
  height: 50px;
  border-right: 1px solid #fff;
  padding-top: 10px;
  font-weight: bold; }

.rippleOut:active {
  background: #84BD00; }

.profile-menu {
  height: 50px;
  color: #84BD00 !important;
  cursor: pointer; }

.profile-menu:active {
  color: #555 !important; }

.pulsate-bck {
  -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
  animation: pulsate-bck 0.5s ease-in-out infinite both; }

.text-sidebar-name {
  color: #555 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding-left: 7% !important; }

.text-sidebar-email {
  color: #84BD00 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding-left: 7% !important; }

@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.ic-social-footer {
  width: 100%; }

.row-btn-sidebar {
  margin: 30px 80px; }

.cart-empty {
  color: #e9e9e9;
  margin-top: 90px;
  margin-left: 37%; }

.avatar-user {
  height: 60px;
  border-radius: 100%;
  margin-top: -45px;
  margin-left: 37%;
  border: 4px solid #fff;
  margin-bottom: 9px; }

.square-right {
  right: 0 !important; }

.text-footer-small {
  font-size: 10px;
  color: #fff;
  font-weight: bold; }

.title-green {
  margin: auto; }

.lines-title-calculator {
  margin-right: auto;
  margin-left: auto;
  height: 35px; }

.avatar-user {
  margin-left: auto;
  margin-right: auto; }

.img-icon-calc {
  margin: auto; }

.btn-hover {
  width: 150px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  height: 45px;
  text-align: center;
  border: none;
  background-size: 200% 100%;
  border-radius: 50px;
  /* bottom: 20px; */
  position: fixed;
  bottom: 60px;
  z-index: 2;
  moz-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  margin: auto;
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both; }

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out; }

.btn-hover:focus {
  outline: none; }

.btn-hover.color-8 {
  background-image: -webkit-linear-gradient(left, #737373, #494a48, #555, #70706e);
  background-image: linear-gradient(to right, #737373, #494a48, #555, #70706e);
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75); }

.btn-hover--hidden {
  -webkit-animation: fade-out-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-out-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@-webkit-keyframes fade-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0; } }

@keyframes fade-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0; } }

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

.title-list-view {
  font-size: 0.8rem !important;
  font-weight: bold !important;
  line-height: 1.5 !important; }

.progress-program-tag {
  background-color: #eeeeee !important;
  border-radius: 0px !important;
  height: 0.2rem !important;
  border: none !important; }

ul.ks-cboxtags {
  list-style: none; }

ul.ks-cboxtags li {
  display: inline; }

ul.ks-cboxtags li label {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #84bd02;
  color: #84bd02;
  border-radius: 25px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: all .2s;
  transition: all .2s; }

ul.ks-cboxtags li label {
  padding: 8px 12px; }

ul.ks-cboxtags li label::before {
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  -webkit-transition: -webkit-transform .3s ease-in-out;
  transition: -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out; }

ul.ks-cboxtags li input[type="checkbox"]:checked + label::before {
  -webkit-transition: -webkit-transform .3s ease-in-out;
  transition: -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out; }

ul.ks-cboxtags li input[type="checkbox"]:checked + label {
  border: 2px solid #84bd02;
  background-color: #84bd02;
  color: #fff;
  -webkit-transition: all .2s;
  transition: all .2s; }

ul.ks-cboxtags li input[type="checkbox"] {
  display: absolute; }

ul.ks-cboxtags li input[type="checkbox"] {
  position: absolute;
  opacity: 0; }

ul.ks-cboxtags li input[type="checkbox"]:focus + label {
  border: 2px solid #e9a1ff; }

.hide {
  display: none; }

.show {
  visibility: visible; }

.selected-round {
  display: none; }
  .selected-round:not(:disabled) ~ label {
    cursor: pointer; }
  .selected-round:disabled ~ label {
    color: #bcc2bf;
    border-color: #bcc2bf;
    box-shadow: none;
    cursor: not-allowed; }

.round-label {
  height: 100%;
  background: white;
  border: 1px solid #84BD00;
  border-radius: 20px;
  margin-bottom: 1rem;
  text-align: center;
  position: relative;
  padding: 5px 10px; }

.selected-round:checked + label {
  background: #84BD00;
  color: white; }
  .selected-round:checked + label::after {
    color: #3d3f43;
    position: absolute;
    top: -25px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    background: white; }

.btn-modal-infaq {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #84bd02;
  cursor: pointer;
  height: 50px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  border: none;
  background-size: 200% 100%;
  border-radius: 50px;
  bottom: 60px;
  z-index: 2;
  moz-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  margin: auto;
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both; }

.image-profile-hashtag {
  border: 5px solid #f5f8fa;
  height: 80px;
  width: 80px;
  border-radius: 100px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  top: -40px;
  margin-bottom: -190px;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.line-home {
  background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(191, 191, 191, 0.75)), to(transparent));
  background-image: -webkit-linear-gradient(left, transparent, rgba(191, 191, 191, 0.75), transparent);
  background-image: linear-gradient(90deg, transparent, rgba(191, 191, 191, 0.75), transparent);
  height: 1px;
  display: block;
  width: 50%;
  margin-bottom: 20px; }

.text-amount-zakat {
  font-weight: bold;
  font-size: 14px; }

label#label-radio-zakat {
  background: none !important;
  color: #555 !important;
  background-image: none !important; }

/* Landscape */
@media only screen and (max-width: 480px) and (orientation: landscape) {
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    margin: 10px !important;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important;
    height: 40px; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff !important;
    font-weight: 600; }
  .calculator {
    font-size: 12px !important;
    margin-bottom: 30px; }
  .img-icon-calc {
    padding-top: 30px;
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 29px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 20px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 20px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 13px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .logo-header {
    width: 145px;
    margin-left: 60%;
    margin-top: 12px; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-sidebar {
    width: 170px;
    padding: 0px;
    margin: 0px 0px 0px 30%; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 15px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 45px !important;
    width: 70% !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 200px !important;
    margin-top: -20px !important;
    background: #84BD00; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important;
    background: #84BD00; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 115px;
    background: #fff;
    margin-left: 12px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .row-menu {
    padding: 10px; }
  .row-service {
    margin-bottom: 70px;
    margin-left: 10px !important;
    padding: 0px 40px; }
  .row-service-top {
    margin-top: 10px !important; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 110px;
    right: 0 !important;
    z-index: -1; }
  .square-left {
    position: absolute;
    width: 127px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: #fff;
    padding: 0px 7px; }
  .slider {
    height: 120px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%;
    height: 165px !important; }
  .img-service {
    width: 100%;
    height: 44px; }
  .service-title-3 {
    font-size: 9px;
    line-height: normal !important;
    padding-top: 6px;
    font-weight: 600 !important;
    color: #555 !important;
    text-align: center !important; }
  .service-title-4 {
    font-size: 11px;
    line-height: normal !important;
    padding-top: 6px;
    text-align: center !important; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 20%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 100%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 180px;
    width: 100%;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    width: 400px;
    height: 150px;
    color: #555 !important; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #84BD00;
    text-align: center; }
  .img-layanan {
    height: 42px;
    margin-left: 30%; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 40px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 120px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 30px; }
  .btn-playstore {
    width: 120px !important;
    margin: 15px 0px 15px 40px !important; }
  .footer-dashboard {
    height: 220px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .span-find-social {
    color: #fff;
    margin-left: 55px;
    margin-top: 25px;
    font-weight: bold;
    font-size: 14px; }
  .progress-program {
    background-color: #fff;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 80px !important;
    border-radius: 100% !important; }
  .service-title-others {
    line-height: normal !important;
    text-align: center !important;
    font-size: 13px;
    font-weight: 600 !important;
    color: #555 !important;
    margin: 0 auto;
    margin-top: 20%; }
  .img-update-detail {
    height: 135px !important;
    width: 190px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #555 !important;
    border: none !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 93.6%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  .service-square-4 {
    height: 100px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 100px;
    max-width: 30% !important;
    margin: 2px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 280px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 17px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  details {
    width: 65%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .img-layanan-4 {
    height: 38px;
    margin-left: 35%; }
  .link-active:active {
    border-bottom: 2px solid #84BD00; }
  .button-share {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .close-sidebar {
    width: 25% !important; }
  .img-profile-sidebar {
    height: 63px !important;
    border-radius: 100% !important;
    margin-top: -10px !important;
    border: 2px solid #fff !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-popular-row {
    margin-top: 70px;
    margin-bottom: 20px;
    padding: 0px 50px 0px 50px; }
  .box-program-col {
    padding: 50px; }
  .row-calculator {
    padding: 40px; }
  .text-footer-small {
    text-align: right;
    color: #fff;
    font-weight: bold;
    font-size: 10px; }
  .btn-login-sidebar {
    background: #555 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .btn-regis-sidebar {
    background: #84BD00 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .pd-ls {
    padding: 0px 40px; }
  .pd-ls-lg {
    padding: 0px 50px; }
  .btn-pull {
    margin-left: 30%; }
  .row-child-sidebar, .row-btn-sidebar {
    padding: 10px 40px !important; }
  .ic-social-footer {
    width: 75% !important; }
  .cart-empty {
    margin-top: 5% !important;
    margin-left: 40% !important; } }

/* Landscape */
@media only screen and (min-width: 481px) and (max-width: 533px) and (orientation: landscape) {
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important;
    height: 40px; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff !important;
    font-weight: 600; }
  .calculator {
    font-size: 12px !important;
    margin-bottom: 30px; }
  .img-icon-calc {
    padding-top: 30px;
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 30px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 30px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 30px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 13px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .logo-header {
    width: 155px;
    margin-left: 70%;
    margin-top: 12px; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-sidebar {
    width: 170px;
    padding: 0px;
    margin: 0px 0px 0px 30%; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 15px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 50px !important;
    width: 75% !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 220px !important;
    margin-top: -20px !important;
    background: #84BD00; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important;
    background: #84BD00; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 115px;
    background: #fff;
    margin-left: 12px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .cart-empty {
    color: #e9e9e9;
    margin-top: 5%;
    margin-left: 40%; }
  .row-menu {
    padding: 10px; }
  .row-service {
    margin-bottom: 70px;
    margin-left: 10px !important;
    padding: 0px 40px; }
  .row-service-top {
    margin-top: 10px !important; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 110px;
    right: 0 !important;
    z-index: -1; }
  .square-left {
    position: absolute;
    width: 127px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: #fff;
    padding: 0px 7px; }
  .slider {
    height: 120px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%;
    height: 185px !important; }
  .img-service {
    width: 100%;
    height: 44px; }
  .service-title-3 {
    font-size: 12px;
    line-height: normal !important;
    padding-top: 6px;
    font-weight: 600 !important;
    color: #555 !important;
    text-align: center !important; }
  .service-title-4 {
    font-size: 11px;
    line-height: normal !important;
    padding-top: 6px;
    text-align: center !important; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 20%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 100%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 180px;
    width: 100%;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    width: 400px;
    height: 150px;
    color: #555 !important; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    color: #84BD00;
    text-align: center; }
  .img-layanan {
    height: 42px;
    margin-left: 35%; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 40px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 120px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 30px; }
  .btn-playstore {
    width: 120px !important;
    margin: 15px 0px 15px 40px !important; }
  .footer-dashboard {
    height: 230px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .span-find-social {
    color: #fff;
    margin-left: 55px;
    margin-top: 25px;
    font-weight: bold;
    font-size: 14px; }
  .progress-program {
    background-color: #fff;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 80px !important;
    border-radius: 100% !important; }
  .service-title-others {
    line-height: normal !important;
    text-align: center !important;
    font-size: 13px;
    font-weight: 600 !important;
    color: #555 !important;
    margin: 0 auto;
    margin-top: 20%; }
  .img-update-detail {
    height: 135px !important;
    width: 190px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #555 !important;
    border: none !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 93.6%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  .service-square-4 {
    height: 100px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 100px;
    max-width: 30% !important;
    margin: 2px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 280px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 17px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  details {
    width: 65%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .img-layanan-4 {
    height: 38px;
    margin-left: 35%; }
  .link-active:active {
    border-bottom: 2px solid #84BD00; }
  .button-share {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .close-sidebar {
    width: 25% !important; }
  .img-profile-sidebar {
    height: 63px !important;
    border-radius: 100% !important;
    margin-top: -10px !important;
    border: 2px solid #fff !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important;
    margin-left: 50% !important; }
  .service-popular-row {
    margin-top: 100px;
    margin-bottom: 20px;
    padding: 0px 50px 0px 50px; }
  .box-program-col {
    padding: 50px; }
  .row-calculator {
    padding: 40px; }
  .text-footer-small {
    text-align: right;
    color: #fff;
    font-weight: bold;
    font-size: 10px; }
  .btn-login-sidebar {
    background: #555 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .btn-regis-sidebar {
    background: #84BD00 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .pd-ls {
    padding: 0px 60px; }
  .pd-ls-lg {
    padding: 0px 70px; }
  .btn-pull {
    margin-left: 30%; }
  .row-child-sidebar, .row-btn-sidebar {
    padding: 10px 90px !important; }
  .ic-social-footer {
    width: 80% !important; } }

/* Landscape */
@media only screen and (min-width: 534px) and (max-width: 568px) and (orientation: landscape) {
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important;
    height: 40px; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff !important;
    font-weight: 600; }
  .calculator {
    font-size: 12px !important;
    margin-bottom: 30px; }
  .img-icon-calc {
    padding-top: 30px;
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 35px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 35px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 35px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 13px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .logo-header {
    width: 155px;
    margin-left: 70%;
    margin-top: 12px; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-sidebar {
    width: 170px;
    padding: 0px;
    margin: 0px 0px 0px 30%; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 15px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 60px !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 230px !important;
    margin-top: -20px !important;
    background: #84BD00; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important;
    background: #84BD00; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 115px;
    background: #fff;
    margin-left: 12px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .row-menu {
    padding: 10px; }
  .row-service {
    margin-bottom: 70px;
    margin-left: 10px !important;
    padding: 0px 40px; }
  .row-service-top {
    margin-top: 10px !important; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 120px;
    z-index: -1;
    right: 0 !important; }
  .square-left {
    position: absolute;
    width: 127px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: #fff;
    padding: 0px 7px; }
  .slider {
    height: 120px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%;
    height: 180px !important; }
  .img-service {
    width: 100%;
    height: 44px; }
  .service-title-3 {
    font-size: 12px;
    line-height: normal !important;
    padding-top: 6px;
    font-weight: 600 !important;
    color: #555 !important;
    text-align: center !important; }
  .service-title-4 {
    font-size: 11px;
    line-height: normal !important;
    padding-top: 6px;
    text-align: center !important; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 20%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 100%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 180px;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    width: 400px;
    height: 150px;
    color: #555 !important; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #84BD00;
    text-align: center;
    font-size: 18px; }
  .img-layanan {
    height: 42px;
    margin-left: 50%; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 40px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 120px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 30px; }
  .btn-playstore {
    width: 120px !important;
    margin: 15px 0px 15px 40px !important; }
  .footer-dashboard {
    height: 240px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .span-find-social {
    color: #fff;
    margin-left: 55px;
    margin-top: 25px;
    font-weight: bold;
    font-size: 14px; }
  .progress-program {
    background-color: #fff;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 80px !important;
    border-radius: 100% !important; }
  .service-title-others {
    line-height: normal !important;
    text-align: center !important;
    font-size: 13px;
    font-weight: 600 !important;
    color: #555 !important;
    margin: 0 auto;
    margin-top: 20%; }
  .img-update-detail {
    height: 135px !important;
    width: 190px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #555 !important;
    border: none !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 93.6%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  .service-square-4 {
    height: 100px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 100px;
    max-width: 30% !important;
    margin: 2px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 280px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 17px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  details {
    width: 65%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .img-layanan-4 {
    height: 38px;
    margin-left: 35%; }
  .link-active:active {
    border-bottom: 2px solid #84BD00; }
  .button-share {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .close-sidebar {
    width: 25% !important; }
  .img-profile-sidebar {
    height: 63px !important;
    border-radius: 100% !important;
    margin-top: -10px !important;
    border: 2px solid #fff !important;
    margin-left: 50% !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-popular-row {
    margin-top: 70px;
    margin-bottom: 20px;
    padding: 0px 50px 0px 50px; }
  .box-program-col {
    padding: 50px; }
  .row-calculator {
    padding: 40px; }
  .text-footer-small {
    text-align: right;
    color: #fff;
    font-weight: bold;
    font-size: 10px; }
  .btn-login-sidebar {
    background: #555 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .btn-regis-sidebar {
    background: #84BD00 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .pd-ls {
    padding: 0px 40px; }
  .pd-ls-lg {
    padding: 0px 60px; }
  .btn-pull {
    margin-left: 30%; }
  .row-child-sidebar, .row-btn-sidebar {
    padding: 10px 90px !important; } }

/* Landscape */
@media only screen and (min-width: 736px) and (max-width: 740px) and (orientation: landscape) {
  .container {
    max-width: none !important; }
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important;
    height: 40px; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff !important;
    font-weight: 600; }
  .calculator {
    font-size: 12px !important;
    margin-bottom: 30px; }
  .img-icon-calc {
    padding-top: 30px;
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 60px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 60px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 60px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 13px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .logo-header {
    width: 195px !important;
    margin-left: 98%;
    margin-top: 12px; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-sidebar {
    width: 180px;
    padding: 0px;
    margin: 0px 0px 0px 35%; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 15px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 78px !important;
    width: 85% !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 270px !important;
    margin-top: -20px !important;
    background: #84BD00; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important;
    background: #84BD00; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 115px;
    background: #fff;
    margin-left: 12px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .row-menu {
    padding: 10px; }
  .row-service {
    margin-bottom: 70px;
    margin-left: 10px !important;
    padding: 0px 120px; }
  .row-service-top {
    margin-top: 10px !important; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 140px;
    z-index: -1;
    right: 0 !important; }
  .square-left {
    position: absolute;
    width: 127px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: #fff;
    padding: 0px 7px; }
  .slider {
    height: 120px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%;
    height: 220px !important; }
  .img-service {
    width: 100%;
    height: 44px; }
  .service-title-3 {
    font-size: 12px;
    line-height: normal !important;
    padding-top: 6px;
    font-weight: 600 !important;
    color: #555 !important;
    text-align: center !important; }
  .service-title-4 {
    font-size: 11px;
    line-height: normal !important;
    padding-top: 6px;
    text-align: center !important; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 20%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 100%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 180px;
    width: 100%;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    width: 400px;
    height: 150px;
    color: #555 !important; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #84BD00;
    text-align: center; }
  .img-layanan {
    height: 42px;
    margin-left: 45%; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 40px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 120px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 30px; }
  .btn-playstore {
    width: 120px !important;
    margin: 15px 0px 15px 40px !important; }
  .footer-dashboard {
    height: 300px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .span-find-social {
    color: #fff;
    margin-left: 100px;
    margin-top: 25px;
    font-weight: bold;
    font-size: 16px; }
  .progress-program {
    background-color: #fff;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 80px !important;
    border-radius: 100% !important; }
  .service-title-others {
    line-height: normal !important;
    text-align: center !important;
    font-size: 13px;
    font-weight: 600 !important;
    color: #555 !important;
    margin: 0 auto;
    margin-top: 20%; }
  .img-update-detail {
    height: 135px !important;
    width: 190px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #84BD00 !important;
    border: none !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 93.6%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  .service-square-4 {
    height: 100px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 100px;
    max-width: 30% !important;
    margin: 2px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 280px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 17px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  details {
    width: 65%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .img-layanan-4 {
    height: 38px;
    margin-left: 35%; }
  .link-active:active {
    border-bottom: 2px solid #84BD00; }
  .button-share {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .close-sidebar {
    width: 25% !important; }
  .img-profile-sidebar {
    height: 63px !important;
    border-radius: 100% !important;
    margin-top: -10px !important;
    border: 2px solid #fff !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important;
    margin-left: 50% !important; }
  .service-popular-row {
    margin-top: 70px;
    margin-bottom: 20px;
    padding: 50px 110px 0px 110px; }
  .box-program-col {
    padding: 50px 100px 0px 100px; }
  .row-calculator {
    padding: 40px; }
  .text-footer-small {
    text-align: right;
    color: #fff;
    font-weight: bold;
    font-size: 10px; }
  .btn-login-sidebar {
    background: #555 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .btn-regis-sidebar {
    background: #84BD00 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .pd-ls {
    padding: 0px 100px; }
  .pd-ls-lg {
    padding: 0px 100px; }
  .btn-pull {
    margin-left: 30%; }
  .row-child-sidebar, .row-btn-sidebar {
    padding: 10px 120px !important; }
  .ic-social-footer {
    margin-top: 30px;
    width: 60% !important; } }

/* Landscape */
@media only screen and (min-width: 740px) and (max-width: 813px) and (orientation: landscape) {
  .container {
    max-width: none !important; }
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important;
    height: 40px; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff !important;
    font-weight: 600; }
  .calculator {
    font-size: 12px !important;
    margin-bottom: 30px; }
  .img-icon-calc {
    padding-top: 30px;
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 70px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 65px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 65px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 13px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .logo-header {
    width: 175px;
    margin-left: 200px;
    margin-top: 12px; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-sidebar {
    width: 185px;
    padding: 0px;
    margin: 0px 0px 0px 35%; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 15px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 80px !important;
    width: 80% !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 265px !important;
    margin-top: -20px !important;
    background: #84BD00; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important;
    background: #84BD00; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 115px;
    background: #fff;
    margin-left: 12px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .row-menu {
    padding: 10px; }
  .row-service {
    margin-bottom: 70px;
    margin-left: 10px !important;
    padding: 0px 140px; }
  .row-service-top {
    margin-top: 10px !important; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 140px;
    z-index: -1;
    right: 0; }
  .square-left {
    position: absolute;
    width: 127px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: #fff;
    padding: 0px 7px; }
  .slider {
    height: 120px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%;
    height: 260px !important; }
  .img-service {
    width: 100%;
    height: 44px; }
  .service-title-3 {
    font-size: 12px;
    line-height: normal !important;
    padding-top: 6px;
    font-weight: 600 !important;
    color: #555 !important;
    text-align: center !important; }
  .service-title-4 {
    font-size: 11px;
    line-height: normal !important;
    padding-top: 6px;
    text-align: center !important; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 20%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 100%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 180px;
    width: 100%;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    width: 400px;
    height: 150px;
    color: #555 !important; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #84BD00;
    font-size: 18px;
    text-align: center; }
  .img-layanan {
    height: 42px;
    margin-left: 40%; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 30px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 120px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 30px; }
  .btn-playstore {
    width: 120px !important;
    margin: 15px 0px 15px 80px !important; }
  .footer-dashboard {
    height: 300px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .footer-bottom {
    height: 40px !important; }
  .ic-social-footer {
    width: 70% !important;
    margin-top: 30px; }
  .span-find-social {
    color: #fff;
    margin-top: 50px;
    font-weight: bold;
    font-size: 16px;
    margin-left: 15%; }
  .progress-program {
    background-color: #fff;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 80px !important;
    border-radius: 100% !important; }
  .service-title-others {
    line-height: normal !important;
    text-align: center !important;
    font-size: 13px;
    font-weight: 600 !important;
    color: #555 !important;
    margin: 0 auto;
    margin-top: 20%; }
  .img-update-detail {
    height: 135px !important;
    width: 190px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #84BD00 !important;
    border: none !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 93.6%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  .service-square-4 {
    height: 100px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 100px;
    max-width: 30% !important;
    margin: 2px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 280px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 17px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  details {
    width: 65%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .img-layanan-4 {
    height: 38px;
    margin-left: 35%; }
  .link-active:active {
    border-bottom: 2px solid #84BD00; }
  .button-share {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .close-sidebar {
    width: 25% !important; }
  .img-profile-sidebar {
    height: 73px !important;
    border-radius: 100% !important;
    margin-top: -10px !important;
    border: 2px solid #fff !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important;
    margin-left: 50%; }
  .service-popular-row {
    margin-top: 25%;
    margin-bottom: 20px;
    padding: 0px 150px 0px 150px; }
  .box-program-col {
    padding: 0px 150px; }
  .row-calculator {
    padding: 40px; }
  .text-footer-small {
    text-align: right;
    color: #fff;
    font-weight: bold;
    font-size: 12px; }
  .text-sidebar-name {
    font-size: 16px !important; }
  .btn-login-sidebar {
    background: #555 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .btn-regis-sidebar {
    background: #84BD00 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .pd-ls, .row-calculator, .col-list-donation {
    padding: 0px 120px; }
  .row-info-profile {
    padding: 0px 90px; }
  .pd-ls-lg {
    padding: 0px 130px; }
  .btn-pull {
    margin-left: 35%; }
  .row-child-sidebar, .row-btn-sidebar {
    padding: 10px 120px !important; } }

/* Landscape */
@media only screen and (min-width: 813px) and (max-width: 846px) and (orientation: landscape) {
  .container {
    max-width: none !important; }
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important;
    height: 40px; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff !important;
    font-weight: 600; }
  .calculator {
    font-size: 12px !important;
    margin-bottom: 30px; }
  .img-icon-calc {
    padding-top: 30px;
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 79px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 70px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 70px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 13px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .logo-header {
    width: 175px;
    margin-left: 225px;
    margin-top: 12px; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-sidebar {
    width: 200px;
    padding: 0px;
    margin: 0px 0px 0px 35%; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 15px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 85px !important;
    width: 85% !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 280px !important;
    margin-top: -20px !important;
    background: #84BD00; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important;
    background: #84BD00; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 115px;
    background: #fff;
    margin-left: 12px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .row-menu {
    padding: 10px; }
  .row-service {
    margin-bottom: 70px;
    margin-left: 10px !important;
    padding: 0px 130px; }
  .row-service-top {
    margin-top: 10px !important; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 140px;
    z-index: -1;
    right: 0 !important; }
  .square-left {
    position: absolute;
    width: 127px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: #fff;
    padding: 0px 7px; }
  .slider {
    height: 120px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%;
    height: 260px !important; }
  .img-service {
    width: 100%;
    height: 44px; }
  .service-title-3 {
    font-size: 12px;
    line-height: normal !important;
    padding-top: 6px;
    font-weight: 600 !important;
    color: #555 !important;
    text-align: center !important; }
  .service-title-4 {
    font-size: 11px;
    line-height: normal !important;
    padding-top: 6px;
    text-align: center !important; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 20%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 100%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 180px;
    width: 100%;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    width: 400px;
    height: 150px;
    color: #555 !important; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #84BD00;
    text-align: center; }
  .img-layanan {
    height: 42px;
    margin-left: 55%; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 40px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 120px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 30px; }
  .btn-playstore {
    width: 130px !important;
    margin: 25px 25px 15px 100px !important; }
  .footer-dashboard {
    height: 300px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .footer-bottom {
    height: 45px !important; }
  .span-find-social {
    color: #fff;
    margin-left: 55px;
    margin-top: 25px;
    font-weight: bold;
    font-size: 16px; }
  .progress-program {
    background-color: #fff;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 80px !important;
    border-radius: 100% !important; }
  .service-title-others {
    line-height: normal !important;
    text-align: center !important;
    font-size: 13px;
    font-weight: 600 !important;
    color: #555 !important;
    margin: 0 auto;
    margin-top: 20%; }
  .img-update-detail {
    height: 135px !important;
    width: 190px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #84BD00 !important;
    border: none !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 93.6%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  .service-square-4 {
    height: 100px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 100px;
    max-width: 30% !important;
    margin: 2px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 280px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 17px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  .ic-social-footer {
    width: 65% !important; }
  details {
    width: 65%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .img-layanan-4 {
    height: 38px;
    margin-left: 35%; }
  .link-active:active {
    border-bottom: 2px solid #84BD00; }
  .button-share {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .close-sidebar {
    width: 25% !important; }
  .img-profile-sidebar {
    height: 63px !important;
    border-radius: 100% !important;
    margin-top: -10px !important;
    border: 2px solid #fff !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important;
    margin-left: 100px; }
  .service-popular-row {
    margin-top: 25%;
    margin-bottom: 20px;
    padding: 0px 140px 0px 140px; }
  .box-program-col {
    padding: 0px 130px; }
  .row-calculator {
    padding: 40px; }
  .text-footer-small {
    text-align: right;
    color: #fff;
    font-weight: bold;
    font-size: 12px; }
  .text-sidebar-name {
    font-size: 16px !important; }
  .btn-login-sidebar {
    background: #555 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .btn-regis-sidebar {
    background: #84BD00 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .pd-ls, .row-calculator {
    padding: 0px 120px; }
  .row-info-profile {
    padding: 0px 90px; }
  .pd-ls-lg {
    padding: 0px 130px; }
  .btn-pull {
    margin-left: 35%; }
  .row-child-sidebar, .row-btn-sidebar {
    padding: 10px 120px !important; } }

/* Landscape */
@media only screen and (min-width: 568px) and (max-width: 598px) and (orientation: landscape) {
  .container {
    max-width: none !important; }
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important;
    height: 40px; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff !important;
    font-weight: 600; }
  .calculator {
    font-size: 12px !important;
    margin-bottom: 30px; }
  .img-icon-calc {
    padding-top: 30px;
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 35px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 35px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 35px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 13px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .logo-header {
    width: 170px;
    margin-left: 70%;
    margin-top: 12px; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-sidebar {
    width: 170px;
    padding: 0px;
    margin: 0px 0px 0px 32%; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 15px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 70px !important;
    width: 88% !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 250px !important;
    margin-top: -20px !important;
    background: #84BD00; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important;
    background: #84BD00; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 115px;
    background: #fff;
    margin-left: 12px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .row-menu {
    padding: 10px; }
  .row-service {
    margin-bottom: 70px;
    margin-left: 10px !important;
    padding: 0px 40px; }
  .row-service-top {
    margin-top: 10px !important; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 120px;
    z-index: -1;
    right: 0 !important; }
  .square-left {
    position: absolute;
    width: 127px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: #fff;
    padding: 0px 7px; }
  .slider {
    height: 120px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%; }
  .img-service {
    width: 100%;
    height: 44px; }
  .service-title-3 {
    font-size: 12px;
    line-height: normal !important;
    padding-top: 6px;
    font-weight: 600 !important;
    color: #555 !important;
    text-align: center !important; }
  .service-title-4 {
    font-size: 11px;
    line-height: normal !important;
    padding-top: 6px;
    text-align: center !important; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 20%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 100%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 180px;
    width: 100%;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    width: 400px;
    height: 150px;
    color: #555 !important; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #84BD00;
    text-align: center;
    font-size: 18px; }
  .img-layanan {
    height: 42px;
    margin-left: 50%; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 40px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 120px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 30px; }
  .btn-playstore {
    width: 120px !important;
    margin: 15px 0px 15px 40px !important; }
  .footer-dashboard {
    height: 250px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .span-find-social {
    color: #fff;
    margin-left: 55px;
    margin-top: 25px;
    font-weight: bold;
    font-size: 14px; }
  .progress-program {
    background-color: #fff;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 80px !important;
    border-radius: 100% !important; }
  .service-title-others {
    line-height: normal !important;
    text-align: center !important;
    font-size: 13px;
    font-weight: 600 !important;
    color: #555 !important;
    margin: 0 auto;
    margin-top: 20%;
    color: #555 !important; }
  .img-update-detail {
    height: 135px !important;
    width: 190px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #84BD00 !important;
    border: none !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 93.6%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  .service-square-4 {
    height: 100px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 100px;
    max-width: 30% !important;
    margin: 2px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 280px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 17px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  details {
    width: 65%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .img-layanan-4 {
    height: 38px;
    margin-left: 35%; }
  .link-active:active {
    border-bottom: 2px solid #84BD00; }
  .button-share {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .close-sidebar {
    width: 25% !important; }
  .img-profile-sidebar {
    height: 63px !important;
    border-radius: 100% !important;
    margin-top: -10px !important;
    border: 2px solid #fff !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important;
    margin-left: 50%; }
  .service-popular-row {
    margin-top: 70px;
    margin-bottom: 20px;
    padding: 0px 50px 0px 50px; }
  .box-program-col {
    padding: 50px; }
  .row-calculator {
    padding: 40px; }
  .text-footer-small {
    text-align: right;
    color: #fff;
    font-weight: bold;
    font-size: 10px; }
  .btn-login-sidebar {
    background: #555 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .btn-regis-sidebar {
    background: #84BD00 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .pd-ls {
    padding: 0px 40px; }
  .pd-ls-lg {
    padding: 0px 60px; }
  .btn-pull {
    margin-left: 30%; }
  .row-child-sidebar, .row-btn-sidebar {
    padding: 10px 90px !important; }
  .ic-social-footer {
    width: 80% !important; } }

/* Landscape */
@media only screen and (min-width: 598px) and (max-width: 667px) and (orientation: landscape) {
  .container {
    max-width: none !important; }
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important;
    height: 40px; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff !important;
    font-weight: 600; }
  .calculator {
    font-size: 12px !important;
    margin: 0px 20px; }
  .img-icon-calc {
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 40px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 40px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 40px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 13px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .logo-header {
    width: 150px;
    margin-left: 95%;
    margin-top: 12px; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-sidebar {
    width: 170px;
    padding: 0px;
    margin: 0px 0px 0px 32%; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 15px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 50px !important;
    width: 80% !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 230px !important;
    margin-top: -20px !important;
    background: #84BD00; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important;
    background: #84BD00; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 115px;
    background: #fff;
    margin-left: 12px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .row-menu {
    padding: 10px; }
  .row-service {
    margin-bottom: 70px;
    margin-left: 10px !important;
    padding: 0px 40px; }
  .row-service-top {
    margin-top: 10px !important; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 130px;
    z-index: -1;
    right: 0 !important; }
  .square-left {
    position: absolute;
    width: 127px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: #fff;
    padding: 0px 7px; }
  .slider {
    height: 120px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%; }
  .img-service {
    width: 100%;
    height: 44px; }
  .service-title-3 {
    font-size: 12px;
    line-height: normal !important;
    padding-top: 6px;
    font-weight: 600 !important;
    color: #555 !important;
    text-align: center !important; }
  .service-title-4 {
    font-size: 11px;
    line-height: normal !important;
    padding-top: 6px;
    text-align: center !important; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 20%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 100%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 180px;
    width: 100%;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    width: 400px;
    height: 150px;
    color: #555 !important; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #84BD00;
    text-align: center; }
  .img-layanan {
    height: 42px;
    margin-left: 50%; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 40px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 120px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 30px; }
  .btn-playstore {
    width: 120px !important;
    margin: 15px 0px 15px 40px !important; }
  .footer-dashboard {
    height: 260px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .span-find-social {
    color: #fff;
    margin-left: 55px;
    margin-top: 25px;
    font-weight: bold;
    font-size: 14px; }
  .ic-social-footer {
    width: 80%; }
  .progress-program {
    background-color: #fff;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 80px !important;
    border-radius: 100% !important; }
  .service-title-others {
    line-height: normal !important;
    text-align: center !important;
    font-size: 13px;
    font-weight: 600 !important;
    color: #555 !important;
    margin: 0 auto;
    margin-top: 20%; }
  .img-update-detail {
    height: 135px !important;
    width: 190px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #84BD00 !important;
    border: none !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 93.6%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  .service-square-4 {
    height: 100px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 100px;
    max-width: 30% !important;
    margin: 2px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 280px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 17px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  details {
    width: 65%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .img-layanan-4 {
    height: 38px;
    margin-left: 35%; }
  .link-active:active {
    border-bottom: 2px solid #84BD00; }
  .button-share {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .close-sidebar {
    width: 25% !important; }
  .img-profile-sidebar {
    height: 63px !important;
    border-radius: 100% !important;
    margin-top: -10px !important;
    border: 2px solid #fff !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important;
    margin-left: 50% !important; }
  .service-popular-row {
    margin-top: 70px;
    margin-bottom: 20px;
    padding: 0px 50px 0px 50px; }
  .box-program-col {
    padding: 0px 100px; }
  .row-calculator {
    padding: 40px; }
  .text-footer-small {
    text-align: right;
    color: #fff;
    font-weight: bold;
    font-size: 10px; }
  .btn-login-sidebar {
    background: #555 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .btn-regis-sidebar {
    background: #84BD00 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .pd-ls {
    padding: 0px 40px; }
  .pd-ls-lg {
    padding: 0px 50px; }
  .btn-pull {
    margin-left: 30%; }
  .row-child-sidebar, .row-btn-sidebar {
    padding: 10px 90px !important; } }

/* Landscape */
@media only screen and (min-width: 667px) and (max-width: 736px) and (orientation: landscape) {
  .container {
    max-width: none !important; }
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important;
    height: 40px; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff !important;
    font-weight: 600; }
  .calculator {
    font-size: 12px !important;
    margin: 30px; }
  .img-icon-calc {
    padding-top: 30px;
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 55px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 55px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 55px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 13px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .logo-header {
    width: 190px;
    margin-left: 90%;
    margin-top: 12px; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-sidebar {
    width: 200px;
    padding: 0px;
    margin: 0px 0px 0px 35%; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 15px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 70px !important;
    margin-bottom: 60px;
    width: 70% !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 250px !important;
    margin-top: -20px !important;
    background: #84BD00; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important;
    background: #84BD00; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 115px;
    background: #fff;
    margin-left: 12px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .row-menu {
    padding: 10px; }
  .row-service {
    margin-bottom: 70px;
    margin-left: 10px !important;
    padding: 0px 120px; }
  .row-service-top {
    margin-top: 20px !important; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 130px;
    z-index: -1;
    right: 0 !important; }
  .square-left {
    position: absolute;
    width: 127px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: #fff;
    padding: 0px 7px; }
  .slider {
    height: 120px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%; }
  .img-service {
    width: 100%;
    height: 44px; }
  .service-title-3 {
    font-size: 12px;
    line-height: normal !important;
    padding-top: 6px;
    font-weight: 600 !important;
    color: #555 !important;
    text-align: center !important; }
  .service-title-4 {
    font-size: 11px;
    line-height: normal !important;
    padding-top: 6px;
    text-align: center !important; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 20%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 100%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 230px;
    width: 100%;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    width: 400px;
    height: 150px;
    color: #555 !important; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    color: #84BD00;
    text-align: center; }
  .img-layanan {
    height: 42px;
    margin-left: 50%; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 40px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 120px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 30px; }
  .btn-playstore {
    width: 120px !important;
    margin: 15px 0px 15px 80px !important; }
  .footer-dashboard {
    height: 260px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .span-find-social {
    color: #fff;
    margin-left: 65px;
    margin-top: 25px;
    font-weight: bold;
    font-size: 16px; }
  .progress-program {
    background-color: #fff;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 80px !important;
    border-radius: 100% !important;
    height: 60px;
    border-radius: 100%;
    margin-top: -45px;
    border: 4px solid #fff;
    margin-bottom: 9px; }
  .service-title-others {
    line-height: normal !important;
    text-align: center !important;
    font-size: 13px;
    font-weight: 600 !important;
    color: #555 !important;
    margin: 0 auto;
    margin-top: 20%; }
  .img-update-detail {
    height: 135px !important;
    width: 190px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #84BD00 !important;
    border: none !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 93.6%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  .service-square-4 {
    height: 120px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 120px;
    max-width: 30% !important;
    margin: 2px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 280px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 17px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  details {
    width: 65%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .img-layanan-4 {
    height: 38px;
    margin-left: 35%; }
  .link-active:active {
    border-bottom: 2px solid #84BD00; }
  .button-share {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .close-sidebar {
    width: 25% !important; }
  .img-profile-sidebar {
    height: 73px !important;
    border-radius: 100% !important;
    margin-top: -15px !important;
    border: 2px solid #fff !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important;
    margin-left: 50%; }
  .service-popular-row {
    margin-top: 140px;
    margin-bottom: 40px;
    padding: 0px 95px; }
  .box-program-col {
    padding: 0px 120px; }
  .row-calculator {
    padding: 40px; }
  .text-footer-small {
    text-align: right;
    color: #fff;
    font-weight: bold;
    font-size: 10px; }
  .btn-login-sidebar {
    background: #555 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .btn-regis-sidebar {
    background: #84BD00 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .pd-ls {
    padding: 0px 95px; }
  .pd-ls-lg {
    padding: 0px 110px; }
  .btn-pull {
    margin-left: 30%; }
  .ic-social-footer {
    width: 60% !important; }
  .row-btn-sidebar {
    margin: 30px 80px !important; }
  .row-child-sidebar {
    margin: 0px 80px !important; }
  .cart-empty {
    margin-left: 43% !important; }
  .row-child-sidebar, .row-btn-sidebar {
    padding: 10px 40px !important; } }

/* Landscape */
@media only screen and (min-width: 846px) and (max-width: 853px) and (orientation: landscape) {
  .container {
    max-width: none !important; }
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important;
    height: 40px; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff !important;
    font-weight: 600; }
  .calculator {
    font-size: 12px !important;
    margin-bottom: 30px; }
  .img-icon-calc {
    padding-top: 30px;
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 79px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 70px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 70px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 13px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .logo-header {
    width: 175px;
    margin-left: 225px;
    margin-top: 12px; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-sidebar {
    width: 200px;
    padding: 0px;
    margin: 0px 0px 0px 35%; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 15px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 85px !important;
    width: 90% !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 280px !important;
    margin-top: -20px !important;
    background: #84BD00; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important;
    background: #84BD00; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 115px;
    background: #fff;
    margin-left: 12px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .row-menu {
    padding: 10px; }
  .row-service {
    margin-bottom: 70px;
    margin-left: 10px !important;
    padding: 0px 130px; }
  .row-service-top {
    margin-top: 10px !important; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 140px;
    z-index: -1;
    right: 0 !important; }
  .square-left {
    position: absolute;
    width: 127px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: #fff;
    padding: 0px 7px; }
  .slider {
    height: 120px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%;
    height: 260px !important; }
  .img-service {
    width: 100%;
    height: 44px; }
  .service-title-3 {
    font-size: 12px;
    line-height: normal !important;
    padding-top: 6px;
    font-weight: 600 !important;
    color: #555 !important;
    text-align: center !important; }
  .service-title-4 {
    font-size: 11px;
    line-height: normal !important;
    padding-top: 6px;
    text-align: center !important; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 20%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 100%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 180px;
    width: 100%;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    width: 400px;
    height: 150px;
    color: #555 !important; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #84BD00;
    text-align: center; }
  .img-layanan {
    height: 42px;
    margin-left: 55%; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 40px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 120px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 30px; }
  .btn-playstore {
    width: 130px !important;
    margin: 25px 25px 15px 100px !important; }
  .footer-dashboard {
    height: 300px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .footer-bottom {
    height: 45px !important; }
  .span-find-social {
    color: #fff;
    margin-left: 55px;
    margin-top: 25px;
    font-weight: bold;
    font-size: 16px; }
  .progress-program {
    background-color: #fff;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 80px !important;
    border-radius: 100% !important; }
  .service-title-others {
    line-height: normal !important;
    text-align: center !important;
    font-size: 13px;
    font-weight: 600 !important;
    color: #555 !important;
    margin: 0 auto;
    margin-top: 20%; }
  .img-update-detail {
    height: 135px !important;
    width: 190px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #555 !important;
    border: none !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 93.6%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  .service-square-4 {
    height: 100px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 100px;
    max-width: 30% !important;
    margin: 2px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 280px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 17px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  .ic-social-footer {
    width: 65% !important; }
  details {
    width: 65%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .img-layanan-4 {
    height: 38px;
    margin-left: 35%; }
  .link-active:active {
    border-bottom: 2px solid #84BD00; }
  .button-share {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .close-sidebar {
    width: 25% !important; }
  .img-profile-sidebar {
    height: 63px !important;
    border-radius: 100% !important;
    margin-top: -10px !important;
    border: 2px solid #fff !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important;
    margin-left: 100px; }
  .service-popular-row {
    margin-top: 25%;
    margin-bottom: 20px;
    padding: 0px 140px 0px 140px; }
  .box-program-col {
    padding: 0px 130px; }
  .row-calculator {
    padding: 40px; }
  .text-footer-small {
    text-align: right;
    color: #fff;
    font-weight: bold;
    font-size: 12px; }
  .text-sidebar-name {
    font-size: 16px !important; }
  .btn-login-sidebar {
    background: #555 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .btn-regis-sidebar {
    background: #84BD00 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .pd-ls, .row-calculator {
    padding: 0px 120px; }
  .row-info-profile {
    padding: 0px 90px; }
  .pd-ls-lg {
    padding: 0px 130px; }
  .btn-pull {
    margin-left: 35%; }
  .row-child-sidebar, .row-btn-sidebar {
    padding: 10px 120px !important; } }

/* Landscape */
@media only screen and (min-width: 853px) and (max-width: 897px) and (orientation: landscape) {
  .container {
    max-width: none !important; }
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important;
    height: 40px; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff !important;
    font-weight: 600; }
  .calculator {
    font-size: 12px !important;
    margin-bottom: 30px; }
  .img-icon-calc {
    padding-top: 30px;
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 79px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 70px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 25px 70px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 13px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .logo-header {
    width: 175px;
    margin-left: 225px;
    margin-top: 12px; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-sidebar {
    width: 200px;
    padding: 0px;
    margin: 0px 0px 0px 35%; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 15px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 65px !important;
    width: 60% !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 280px !important;
    margin-top: -20px !important;
    background: #84BD00; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important;
    background: #84BD00; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 115px;
    background: #fff;
    margin-left: 12px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .row-menu {
    padding: 10px; }
  .row-service {
    margin-bottom: 70px;
    margin-left: 10px !important;
    padding: 0px 130px; }
  .row-service-top {
    margin-top: 10px !important; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 140px;
    z-index: -1;
    right: 0 !important; }
  .square-left {
    position: absolute;
    width: 127px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: #fff;
    padding: 0px 7px; }
  .slider {
    height: 120px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%;
    height: 260px !important; }
  .img-service {
    width: 100%;
    height: 44px; }
  .service-title-3 {
    font-size: 12px;
    line-height: normal !important;
    padding-top: 6px;
    font-weight: 600 !important;
    color: #555 !important;
    text-align: center !important; }
  .service-title-4 {
    font-size: 11px;
    line-height: normal !important;
    padding-top: 6px;
    text-align: center !important; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 20%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 60%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 180px;
    width: 100%;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    width: 400px;
    height: 150px;
    color: #555 !important; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #84BD00;
    text-align: center; }
  .img-layanan {
    height: 42px;
    margin-left: 55%; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 40px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 120px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 30px; }
  .btn-playstore {
    width: 130px !important;
    margin: 25px 25px 15px 100px !important; }
  .footer-dashboard {
    height: 300px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .footer-bottom {
    height: 45px !important; }
  .span-find-social {
    color: #fff;
    margin-left: 55px;
    margin-top: 25px;
    font-weight: bold;
    font-size: 16px; }
  .progress-program {
    background-color: #fff;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 80px !important;
    border-radius: 100% !important; }
  .service-title-others {
    line-height: normal !important;
    text-align: center !important;
    font-size: 13px;
    font-weight: 600 !important;
    color: #555 !important;
    margin: 0 auto;
    margin-top: 20%; }
  .img-update-detail {
    height: 135px !important;
    width: 190px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #555 !important;
    border: none !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 93.6%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  .service-square-4 {
    height: 100px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 100px;
    max-width: 30% !important;
    margin: 2px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 280px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 17px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  .ic-social-footer {
    width: 65% !important; }
  details {
    width: 65%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .img-layanan-4 {
    height: 38px;
    margin-left: 35%; }
  .link-active:active {
    border-bottom: 2px solid #84BD00; }
  .button-share {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .close-sidebar {
    width: 25% !important; }
  .img-profile-sidebar {
    height: 63px !important;
    border-radius: 100% !important;
    margin-top: -10px !important;
    border: 2px solid #fff !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important;
    margin-left: 100px; }
  .service-popular-row {
    margin-top: 25%;
    margin-bottom: 20px;
    padding: 0px 140px 0px 140px; }
  .box-program-col {
    padding: 0px 130px; }
  .row-calculator {
    padding: 40px; }
  .text-footer-small {
    text-align: right;
    color: #fff;
    font-weight: bold;
    font-size: 12px; }
  .text-sidebar-name {
    font-size: 16px !important; }
  .btn-login-sidebar {
    background: #555 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .btn-regis-sidebar {
    background: #84BD00 !important;
    border: none !important;
    font-weight: 600 !important;
    color: white !important;
    height: 45px !important; }
  .pd-ls, .row-calculator {
    padding: 0px 120px; }
  .row-info-profile {
    padding: 0px 90px; }
  .pd-ls-lg {
    padding: 0px 130px; }
  .btn-pull {
    margin-left: 35%; }
  .row-child-sidebar, .row-btn-sidebar {
    padding: 10px 120px !important; } }

@media only screen and (min-width: 384px) and (max-width: 411px) and (orientation: portrait) {
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding-top: 30px;
    font-style: normal;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff;
    font-weight: 600; }
  .calculator {
    font-size: 14px !important;
    margin-bottom: 30px; }
  .img-icon-calc {
    padding-top: 30px;
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 35px 35px 30px 45px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: bold !important;
    padding: 35px 35px 30px 30px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 35px 35px 30px 30px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 14px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .logo-header {
    width: 145px !important;
    margin-left: 40px !important;
    margin-top: 12px !important; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-sidebar {
    width: 150px;
    padding: 0px;
    margin: 0px 0px 0px 90px; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 16px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 49px !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 235px !important;
    margin-top: -20px !important; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 115px;
    background: #fff;
    margin-left: 14px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .row-menu {
    padding: 10px; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 113px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: white;
    padding: 0px 7px; }
  .slider {
    height: 138px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%;
    height: 138px !important; }
  .card-service {
    height: 100px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .box-service {
    margin-top: 20px; }
  .img-service {
    width: 100%;
    height: 57px; }
  .service-title-4 {
    font-size: 13px;
    line-height: normal !important;
    padding-top: 15px;
    color: #555;
    text-align: center !important;
    font-weight: 500; }
  .service-title-3 {
    font-size: 11px;
    line-height: normal !important;
    padding-top: 15px;
    color: #555;
    text-align: center !important;
    font-weight: 600 !important; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 30%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 100%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 220px;
    width: 100%;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    width: 400px;
    height: 180px;
    color: #555 !important; }
  .card-program:active {
    cursor: pointer;
    box-shadow: 8px 15px 0px #616161;
    -webkit-transition: all 500ms ease; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #84BD00;
    text-align: center; }
  .img-layanan {
    height: 54px;
    margin-left: 4px; }
  .footer-dashboard {
    height: 195px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .span-find-social {
    color: #fff;
    margin-left: 80px;
    margin-top: 35px;
    font-weight: bold;
    font-size: 12px; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 45px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 140px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 55px; }
  .btn-playstore {
    width: 95px !important;
    margin-top: 30px !important;
    margin-left: 30px !important;
    margin-bottom: 10px !important; }
  .progress-program {
    background-color: #fff;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 85px !important;
    border-radius: 100% !important; }
  .service-title-others {
    line-height: normal !important;
    text-align: center !important;
    font-size: 13px;
    font-weight: 600 !important;
    color: #555 !important;
    margin: 0 auto;
    margin-top: 20%; }
  .img-update-detail {
    height: 175px !important;
    width: 240px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    border: none !important;
    background: #84BD00 !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 78%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  .row-service {
    margin-bottom: 80px;
    margin-left: 10px !important; }
  .row-service-top {
    margin-top: 10px !important; }
  .service-square-4 {
    height: 130px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 15px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 120px;
    max-width: 30% !important;
    margin: 3px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 340px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 17px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  details {
    width: 85%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 15px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .img-layanan-4 {
    height: 50px;
    margin-left: 0px; }
  .button-share {
    margin-left: 100px; }
  .close-sidebar {
    width: 20% !important; }
  .img-profile-sidebar {
    height: 75px !important;
    border-radius: 100% !important;
    margin-top: -10px !important;
    border: 2px solid #fff !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .btn-category {
    background: #555 !important; } }

/* Portrait */
@media only screen and (min-width: 320px) and (max-width: 360px) and (orientation: portrait) {
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .logo-sidebar {
    padding: 40px; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding-top: 30px;
    font-style: normal;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff !important;
    font-weight: 600; }
  .calculator {
    font-size: 12px !important;
    margin-bottom: 30px; }
  .img-icon-calc {
    padding-top: 30px;
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 29px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 20px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 25px 25px 20px 20px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 13px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .logo-header {
    width: 125px;
    margin-left: 20px;
    margin-top: 12px; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-sidebar {
    width: 150px;
    padding: 0px;
    margin: 0px 0px 0px 55px; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 15px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 49px !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 180px !important;
    margin-top: -20px !important; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 115px;
    background: #fff;
    margin-left: 12px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .row-menu {
    padding: 10px; }
  .row-service {
    margin-bottom: 70px;
    margin-left: 10px !important; }
  .row-service-top {
    margin-top: 10px !important; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 88px;
    z-index: -1; }
  .square-left {
    position: absolute;
    width: 127px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: #fff;
    padding: 0px 7px; }
  .slider {
    height: 120px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%;
    height: 118px !important; }
  .img-service {
    width: 100%;
    height: 44px; }
  .service-title-3 {
    font-size: 9px;
    line-height: normal !important;
    padding-top: 6px;
    font-weight: 600 !important;
    color: #555 !important;
    text-align: center !important; }
  .service-title-4 {
    font-size: 11px;
    line-height: normal !important;
    padding-top: 6px;
    text-align: center !important; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 20%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 100%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 180px;
    width: 100%;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    width: 400px;
    height: 150px;
    color: #555 !important; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #84BD00;
    text-align: center; }
  .img-layanan {
    height: 38px; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 40px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 120px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 30px; }
  .btn-playstore {
    width: 80px !important;
    margin-left: 30px !important;
    margin: 15px !important; }
  .footer-dashboard {
    height: 155px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .span-find-social {
    color: #fff;
    margin-left: 55px;
    margin-top: 25px;
    font-weight: bold;
    font-size: 12px; }
  .progress-program {
    background-color: #fff;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 80px !important;
    border-radius: 100% !important; }
  .icon-dashboard-service {
    margin: 14px 14px 0px 14px; }
  .service-title-others {
    line-height: normal !important;
    padding-top: 18px;
    padding-left: 3px;
    text-align: center !important;
    font-size: 10px;
    font-weight: 600 !important;
    color: #555 !important; }
  .img-update-detail {
    height: 135px !important;
    width: 190px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #84BD00 !important;
    border: none !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 93.6%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  .service-square-4 {
    height: 100px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 100px;
    max-width: 30% !important;
    margin: 2px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 280px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 17px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  details {
    width: 85%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .img-layanan-4 {
    height: 38px; }
  .link-active:active {
    border-bottom: 2px solid #84BD00; }
  .button-share {
    margin-left: 70px; }
  .close-sidebar {
    width: 25% !important; }
  .img-profile-sidebar {
    height: 63px !important;
    border-radius: 100% !important;
    margin-top: -10px !important;
    border: 2px solid #fff !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .text-footer-small {
    font-size: 9px !important; }
  .btn-category {
    background: #555 !important; } }

@media only screen and (min-width: 411px) and (max-width: 425px) and (orientation: portrait) {
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .logo-sidebar {
    padding: 40px; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding-top: 30px;
    font-style: normal;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff !important;
    font-weight: 600; }
  .calculator {
    font-size: 14px !important;
    margin-bottom: 30px; }
  .img-icon-calc {
    padding-top: 30px;
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 35px 35px 30px 45px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: bold !important;
    padding: 35px 35px 30px 30px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 35px 35px 30px 30px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 14px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .logo-header {
    width: 145px !important;
    margin-left: 50px !important;
    margin-top: 12px !important; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-sidebar {
    width: 150px;
    padding: 0px;
    margin: 0px 0px 0px 100px; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 16px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 49px !important;
    width: 90% !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 240px !important;
    margin-top: -20px !important; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 115px;
    background: #fff;
    margin-left: 14px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .row-menu {
    padding: 10px; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 118px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: white;
    padding: 0px 7px; }
  .slider {
    height: 138px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%;
    height: 138px !important; }
  .card-service {
    height: 100px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .box-service {
    margin-top: 20px; }
  .img-service {
    width: 100%;
    height: 57px; }
  .service-title-4 {
    font-size: 13px;
    line-height: normal !important;
    padding-top: 15px;
    color: #555;
    text-align: center !important;
    font-weight: 500; }
  .service-title-3 {
    font-size: 12px;
    line-height: normal !important;
    padding-top: 15px;
    color: #555;
    text-align: center !important;
    font-weight: 600 !important; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 30%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 100%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 230px;
    width: 100%;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    width: 400px;
    height: 170px;
    color: #555 !important; }
  .card-program:active {
    cursor: pointer;
    box-shadow: 8px 15px 0px #616161;
    -webkit-transition: all 500ms ease; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #84BD00;
    text-align: center; }
  .img-layanan {
    height: 54px;
    margin-left: 6px; }
  .footer-dashboard {
    height: 200px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .span-find-social {
    color: #fff;
    margin-left: 100px;
    margin-top: 35px;
    font-weight: bold;
    font-size: 12px; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 45px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 140px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 55px; }
  .btn-playstore {
    width: 95px !important;
    margin-top: 30px !important;
    margin-left: 30px !important;
    margin-bottom: 10px !important; }
  .progress-program {
    background-color: #fff;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 85px !important;
    border-radius: 100% !important; }
  .service-title-others {
    line-height: normal !important;
    text-align: center !important;
    font-size: 13px;
    font-weight: 600 !important;
    color: #555 !important;
    margin: 0 auto;
    margin-top: 20%; }
  .img-update-detail {
    height: 185px !important;
    width: 265px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    border: none !important;
    background: #84BD00 !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 73%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  .row-service {
    margin-bottom: 80px;
    margin-left: 10px !important; }
  .row-service-top {
    margin-top: 10px !important; }
  .service-square-4 {
    height: 130px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 15px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 120px;
    max-width: 30% !important;
    margin: 3px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 340px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 17px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  details {
    width: 85%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 15px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .img-layanan-4 {
    height: 50px;
    margin-left: 0px; }
  .button-share {
    margin-left: 120px; }
  .close-sidebar {
    width: 18% !important; }
  .img-profile-sidebar {
    height: 75px !important;
    border-radius: 100% !important;
    margin-top: -10px !important;
    border: 2px solid #fff !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .btn-category {
    background: #555 !important; } }

@media only screen and (min-width: 375px) and (max-width: 384px) and (orientation: portrait) {
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .logo-sidebar {
    padding: 40px; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding-top: 30px;
    font-style: normal;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff !important;
    font-weight: 600; }
  .calculator {
    font-size: 14px !important;
    margin-bottom: 30px; }
  .img-icon-calc {
    padding-top: 30px;
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 30px 30px 25px 38px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: bold !important;
    padding: 30px 30px 25px 26px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 30px 30px 25px 26px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 14px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .logo-header {
    width: 145px !important;
    margin-left: 30px !important;
    margin-top: 12px !important; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-sidebar {
    width: 150px;
    padding: 0px;
    margin: 0px 0px 0px 85px; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 16px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 40px !important;
    width: 80% !important; }
  .card-image {
    margin-top: 5% !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 225px !important;
    margin-top: -20px !important; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 115px;
    background: #fff;
    margin-left: 14px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .row-menu {
    padding: 10px; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 106px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: #fff;
    padding: 0px 7px; }
  .slider {
    height: 138px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%;
    height: 138px !important; }
  .card-service {
    height: 100px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .box-service {
    margin-top: 20px; }
  .img-service {
    width: 100%;
    height: 57px; }
  .service-title-4 {
    font-size: 13px;
    line-height: normal !important;
    padding-top: 15px;
    color: #555;
    text-align: center !important;
    font-weight: 500; }
  .service-title-3 {
    font-size: 11px;
    line-height: normal !important;
    padding-top: 15px;
    color: #555;
    text-align: center !important;
    font-weight: 500; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 50%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 100%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 215px;
    width: 100%;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    width: 400px;
    height: 170px;
    color: #555 !important; }
  .card-program:active {
    cursor: pointer;
    box-shadow: 8px 15px 0px #616161;
    -webkit-transition: all 500ms ease; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #84BD00;
    text-align: center; }
  .img-layanan {
    height: 40px;
    margin-left: 0px; }
  .footer-dashboard {
    height: 175px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .span-find-social {
    color: #fff;
    margin-left: 85px;
    margin-top: 25px;
    font-weight: bold;
    font-size: 12px; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 45px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 140px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 55px; }
  .btn-playstore {
    width: 80px !important;
    margin-left: 40px !important;
    margin-top: 35px !important; }
  .progress-program {
    background-color: #fff;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 85px !important;
    border-radius: 100% !important; }
  .icon-dashboard-service {
    margin: 25px 20px 0px 17px; }
  .service-title-others {
    font-size: 12px;
    line-height: normal !important;
    padding-top: 25px !important;
    padding-left: 6px;
    text-align: center !important;
    font-weight: 600 !important;
    color: #555 !important; }
  .img-update-detail {
    height: 165px !important;
    width: 235px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    border: none !important;
    background: #84BD00 !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 80%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  .row-service {
    margin-bottom: 80px;
    margin-left: 10px !important; }
  .row-service-top {
    margin-top: 10px !important; }
  .service-square-4 {
    height: 130px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 15px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 120px;
    max-width: 30% !important;
    margin: 3px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 340px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 17px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  details {
    width: 85%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 15px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .img-layanan-4 {
    height: 40px;
    margin-left: 0px; }
  .button-share {
    margin-left: 100px; }
  .close-sidebar {
    width: 20% !important; }
  .img-profile-sidebar {
    height: 75px !important;
    border-radius: 100% !important;
    margin-top: -10px !important;
    border: 2px solid #fff !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .btn-category {
    background: #555 !important; } }

@media only screen and (min-width: 360px) and (max-width: 375px) and (orientation: portrait) {
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding-top: 30px;
    font-style: normal;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff !important;
    font-weight: 600; }
  .calculator {
    font-size: 12px !important;
    margin-bottom: 30px; }
  .img-icon-calc {
    padding-top: 30px;
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 30px 30px 25px 38px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: bold !important;
    padding: 30px 30px 25px 28px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 30px 30px 25px 26px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 13px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .logo-header {
    width: 145px !important;
    margin-left: 25px !important;
    margin-top: 12px !important; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-sidebar {
    width: 150px;
    padding: 0px;
    margin: 0px 0px 0px 70px; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 15px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 40px !important;
    width: 85% !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 220px !important;
    margin-top: -20px !important; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 115px;
    background: #fff;
    margin-left: 12px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .row-menu {
    padding: 10px; }
  .row-service {
    margin-bottom: 70px;
    margin-left: 10px !important; }
  .row-service-top {
    margin-top: 10px !important; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 101px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: white;
    padding: 0px 7px; }
  .slider {
    height: 120px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%;
    height: 118px !important; }
  .img-service {
    width: 100%;
    height: 44px; }
  .service-title-3 {
    font-size: 9px;
    line-height: normal !important;
    padding-top: 6px;
    font-weight: 600 !important;
    color: #555 !important;
    text-align: center !important; }
  .service-title-4 {
    font-size: 11px;
    line-height: normal !important;
    padding-top: 6px;
    text-align: center !important; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 5%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 100%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 220px;
    width: 100%;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    width: 400px;
    height: 150px;
    color: #555 !important; }
  .card-program:active {
    cursor: pointer;
    box-shadow: 8px 15px 0px #616161;
    -webkit-transition: all 500ms ease; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #84BD00;
    text-align: center; }
  .img-layanan {
    height: 38px;
    margin-left: 9px; }
  .footer-dashboard {
    height: 180px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .span-find-social {
    color: #fff;
    margin-left: 50px;
    margin-top: 35px;
    font-weight: bold;
    font-size: 12px; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 40px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 120px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 30px; }
  .btn-playstore {
    width: 95px !important;
    margin-top: 30px !important;
    margin-left: 30px !important;
    margin-bottom: 10px !important; }
  .progress-program {
    background-color: white;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 80px !important;
    border-radius: 100% !important; }
  .icon-dashboard-service {
    margin: 17px 17px 0px 17px; }
  .service-title-others {
    line-height: normal !important;
    padding-top: 18px;
    padding-left: 6px;
    text-align: center !important;
    font-size: 10px;
    font-weight: 600 !important;
    color: #555 !important; }
  .img-update-detail {
    height: 155px !important;
    width: 225px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #84BD00 !important;
    border: none !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 83.2%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  /* after data */
  .service-square-4 {
    height: 100px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 100px;
    max-width: 30% !important;
    margin: 2px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 280px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 17px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  details {
    width: 85%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .img-layanan-4 {
    height: 38px;
    margin-left: 3px; }
  .button-share {
    margin-left: 90px; }
  .close-sidebar {
    width: 21% !important; }
  .img-profile-sidebar {
    height: 75px !important;
    border-radius: 100% !important;
    margin-top: -10px !important;
    border: 2px solid #fff !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .btn-category {
    background: #555 !important; } }

@media only screen and (min-width: 425px) and (max-width: 480px) and (orientation: portrait) {
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #555;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .bm-item:hover {
    color: #fff; }
  .logo-sidebar {
    padding: 40px; }
  .title {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding-top: 30px;
    font-style: normal;
    color: #555; }
  .lines-title {
    width: 100px;
    height: 20px;
    margin-bottom: 20px; }
  .btn-green {
    background: #84BD00; }
  .text-green {
    color: #84BD00;
    font-weight: 800; }
  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0; }
  .header-top {
    background: #fff;
    height: 70px;
    overflow: hidden;
    position: fixed !important;
    top: 0; }
  .btn-login {
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    color: #fff !important;
    font-weight: 900 !important; }
  .btn-auth {
    margin: 10px 0px 20px 28px; }
  .btn-google {
    background: #de4933 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px !important;
    margin-top: 6px !important;
    color: #fff !important;
    font-weight: 600 !important; }
  .btn-facebook {
    background: #385699 !important;
    border: none !important;
    width: 100% !important;
    padding: 9px;
    margin-top: 6px;
    color: #fff !important;
    font-weight: 600; }
  .calculator {
    font-size: 14px !important;
    margin-bottom: 30px; }
  .img-icon-calc {
    padding-top: 30px;
    width: 35px; }
  .payment-method {
    background: #fff;
    padding-bottom: 40px; }
  .card-paid {
    background: #439dbb;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 28px 28px 20px 47px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-unpaid {
    background: #e0a630;
    color: #878787 !important;
    position: absolute !important;
    font-weight: bold !important;
    padding: 28px 28px 20px 34px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .card-cancel {
    background: #de4933;
    color: #878787 !important;
    position: absolute !important;
    font-weight: 900 !important;
    padding: 28px 28px 20px 35px !important;
    color: #fff !important;
    -webkit-transform: translateX(-30%) translateY(-24%) rotate(0deg) !important; }
  .select-component {
    font-size: 14px !important;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .header-logo {
    background: #84BD00;
    position: fixed;
    height: 100px; }
  .logo-header {
    width: 170px !important;
    margin-left: 70px !important;
    margin-top: 12px !important; }
  .logo-sidebar {
    width: 150px;
    padding: 0px;
    margin: 0px 0px 0px 110px; }
  .title-calc {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 16px;
    padding-top: 15px;
    color: #555; }
  .lines-title-calculator {
    width: 150px;
    margin-bottom: 25px; }
  .card-payment {
    margin-top: 20px; }
  .img-payment {
    height: 49px !important;
    width: 90% !important; }
  .term-service-general {
    background: #e7e7e7; }
  .title-term-service {
    font-weight: bold;
    color: #555; }
  .content-term-service {
    color: #555;
    font-size: 12px;
    text-align: justify; }
  .ol-number {
    list-style-type: number; }
  .ol-lower-alpha {
    list-style-type: lower-alpha; }
  .date-program {
    text-align: right; }
  .title-green-program {
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    padding: 20px 0px 20px 0px;
    font-style: normal;
    color: #84BD00;
    font-size: 14px; }
  .image-program {
    width: 100%;
    height: 270px !important;
    margin-top: -20px !important; }
  .image-program-detail {
    width: 100%;
    height: 100%;
    margin-top: -0px !important; }
  /* gets the actual input out of the way;
    we're going to style the label instead */
  #drawer-toggle {
    position: absolute;
    opacity: 0; }
  #drawer-toggle-label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0px;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    z-index: 1; }
  /* adds our "hamburger" menu icon */
  #drawer-toggle-label:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #8d8d8d;
    left: 13px;
    top: 18px;
    box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; }
  /* drawer menu pane - note the 0px width */
  #drawer {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: #2f2f2f;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    -webkit-overflow-scrolling: touch; }
  /* actual page content pane */
  #page-content {
    margin-left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px; }
  /* checked styles (menu open state) */
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 200%;
    width: calc(100% - 300px);
    background: rgba(89, 89, 89, 0.8); }
  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: 300px; }
  #drawer-toggle:checked ~ #drawer {
    left: 0px; }
  #drawer-toggle:checked ~ #page-content {
    margin-left: 300px; }
  /* Menu item styles */
  #drawer ul {
    list-style-type: none; }
  #drawer ul a {
    display: block;
    padding: 10px;
    color: #c7c7c7;
    text-decoration: none; }
  #drawer ul a:hover {
    color: #fff; }
  .card-donation {
    width: 100%;
    height: 100px;
    background: #fff;
    margin-left: 18px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .row-menu {
    padding: 10px; }
  .rotate-text {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    float: left;
    margin-top: 25px;
    color: #fff;
    font-weight: bold; }
  .square-right {
    position: absolute;
    width: 127px;
    z-index: -1; }
  .line-vertical {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a9a6a6;
    line-height: 0.1em;
    margin: 30px 0 20px;
    font-size: 17px; }
  .text-login-or {
    background: white;
    padding: 0px 7px; }
  .slider {
    height: 150px;
    padding: 0px !important;
    background: #84BD00;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .services {
    background: #fff;
    padding-bottom: 40px; }
  .img-slider {
    width: 100%;
    height: 150px !important; }
  .card-service {
    height: 125px;
    background: #fff;
    border: 1px solid #fff;
    box-shadow: 0 10px 6px -6px #c3c3c3; }
  .box-service {
    margin-top: 20px; }
  .card-image {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 35%; }
  .icon-dashboard {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 10px; }
  .slick-slide {
    height: 100%;
    box-sizing: border-box; }
  .slick-slide img {
    height: 250px;
    width: 100%;
    box-sizing: border-box; }
  .card-program {
    margin-top: 20px;
    background: #fff;
    height: 200px;
    color: #555 !important; }
  .card-program:active {
    cursor: pointer;
    box-shadow: 8px 15px 0px #616161;
    -webkit-transition: all 500ms ease; }
  .text-collected {
    font-size: 12px;
    padding-left: 7px; }
  .text-date {
    font-size: 12px;
    text-align: right !important;
    float: right; }
  .text-title-program {
    font-weight: bold;
    font-size: 11px; }
  .title-green {
    font-family: "Quicksand", sans-serif;
    font-style: normal;
    color: #84BD00;
    text-align: center;
    font-weight: bold; }
  .img-layanan {
    height: 55px; }
  .footer-dashboard {
    height: 220px;
    background: #424141;
    border-top-left-radius: 0%;
    border-top-right-radius: 90%; }
  .span-find-social {
    color: #fff;
    margin-left: 100px;
    margin-top: 35px;
    font-weight: bold;
    font-size: 12px; }
  .footer-small {
    background-size: cover;
    background-repeat: no-repeat;
    height: 45px; }
  .card-program-list {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #dcdada;
    box-shadow: 0 10px 6px -6px #c3c3c3;
    margin-bottom: 50px; }
  .btn-register-bottom {
    margin-top: 160px;
    font-size: 12px !important;
    background: #555 !important;
    margin-left: 75px; }
  .btn-playstore {
    width: 95px !important;
    margin-top: 30px !important;
    margin-left: 40px !important;
    margin-bottom: 10px !important; }
  .progress-program {
    background-color: #fff;
    border: 1px solid #84BD00;
    border-radius: 0px !important; }
  .bg-success {
    background-color: #84BD00 !important;
    font-weight: bold; }
  .btn-infaq {
    border-radius: 0px !important;
    background: #84BD00 !important;
    margin-top: 20px;
    border: none !important;
    font-weight: bold !important;
    color: #fff !important;
    text-decoration: none !important; }
  .btn-lainnya {
    background-color: #2096f3 !important;
    border: none !important;
    margin-top: 20px;
    margin-bottom: 20px; }
  .avatar-user {
    height: 85px !important;
    border-radius: 100% !important; }
  .service-title-others {
    line-height: normal !important;
    text-align: center !important;
    font-size: 13px;
    font-weight: 600 !important;
    color: #555 !important;
    margin: 0 auto;
    margin-top: 20%; }
  .img-update-detail {
    height: 190px !important;
    width: 270px !important; }
  .button-fixed {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #84BD00 !important;
    border: none !important; }
  .button-fixed-logout {
    overflow: hidden;
    position: fixed !important;
    bottom: 0;
    height: 50px;
    font-weight: 900 !important;
    background: #de4933 !important;
    border: none !important;
    width: 70.6%;
    color: #fff !important; }
  .btn-color-primary {
    background: #84BD00 !important; }
  .btn-color-secondary {
    background: #747474 !important; }
  /* after data */
  .row-service {
    margin-bottom: 80px;
    margin-left: 10px !important; }
  .row-service-top {
    margin-top: 10px !important; }
  .service-square-4 {
    height: 130px;
    max-width: 23.7% !important;
    margin: 2px;
    margin-bottom: 25px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-square-3 {
    height: 140px;
    max-width: 30% !important;
    margin: 3px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .service-title-3 {
    font-size: 12px;
    line-height: normal !important;
    padding-top: 15px;
    color: #555;
    text-align: center !important;
    font-weight: 600 !important; }
  .service-title-4 {
    font-size: 14px;
    line-height: normal !important;
    padding-top: 15px;
    color: #555;
    text-align: center !important;
    font-weight: 500; }
  .img-service {
    width: 100%;
    height: 70px; }
  .tabs-program {
    margin-top: 40px !important;
    margin-bottom: 70px !important; }
  .modal-program-category {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    z-index: 10002;
    width: 380px;
    background-color: #fff;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 24px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    -webkit-transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s; }
  details {
    width: 85%;
    min-height: 5px;
    max-width: 700px;
    padding: 14px 5px 14px 14px;
    margin: 0 auto;
    position: relative;
    font-size: 15px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s; }
  details + details {
    margin-top: 10px; }
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2); }
  details p {
    color: #96999d;
    font-weight: 300; }
  summary {
    font-weight: 500;
    cursor: pointer; }
  summary:focus {
    outline: none; }
  summary::-webkit-details-marker {
    display: none; }
  summary::after {
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-caps: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-weight: 900 !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary::after {
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  details[open] summary:hover::after {
    -webkit-animation: pulse 1s ease;
            animation: pulse 1s ease; }
  @-webkit-keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes pulse {
    25% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .button-share {
    margin-left: 90px;
    margin-left: 125px; }
  .close-sidebar {
    width: 18% !important; }
  .img-profile-sidebar {
    height: 75px !important;
    border-radius: 100% !important;
    margin-top: -10px !important;
    border: 2px solid #fff !important;
    box-shadow: 0 10px 6px -6px #c3c3c3 !important; }
  .btn-category {
    background: #555 !important; } }

